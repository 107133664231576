/**
 * Container component to seperate the main app desktop from the App component
 */

import React from 'react';
import { DesktopContextProvider } from '../contexts/DesktopContext/DesktopContext';

import DesktopManager from './components/DesktopManager/DesktopManager';

import styles from './desktop.module.css';


function LBDesktop() {
  return (
    <div className={styles.desktop}>
      <DesktopContextProvider>
        <DesktopManager />
      </DesktopContextProvider>
    </div>
  );
}

export default LBDesktop;