import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import * as actions from '../../../contexts/DesktopContext/actions';

import usePortal from '../../../portals/hooks/usePortal';
import PopoverPortal from '../../../portals/PopoverPortal';

import { getElementHeight } from '../../../Utils';


import { publish } from '../../../events';
import LBListBody from './components/LBListBody';
import LBListFooter from './components/LBListFooter';
import LBListHeader from './components/LBListHeader';

import styles from './list.module.css';
import SettingPOMenu from './menus/SettingsPOMenu/SettingPOMenu';
import NewListItemPOMenu from './menus/NewListItemPOMenu/NewListItemPOMenu';
import WEPopup from '../../../components/WEPopup/WEPopup';
import AddListItemMenu from './menus/AddListItemMenu/AddListItemMenu';
import { DesktopContext } from '../../../contexts/DesktopContext/DesktopContext';
import SettingsMenu from './menus/SettingsMenu/SettingsMenu';



const createListItem = (listId, value, description) => {
  const retObj = {
    id: "e53628",
    list: listId,
    priority: 1,
    rank: 1,
    type: 0,
    status: 0,
    text: value,
    description: description
  }
  return retObj;
}

const useLBListComponent = () => {
  const { state, dispatch } = useContext(DesktopContext);

  const addListItem = (_list, _listItem) => {
    const listsCopy = [...state.lists];
    let filteredLists = listsCopy.filter(list => list.id === _list);
    if (filteredLists.length === 0) return;
    const targetList = filteredLists[0];
    targetList.items = [...targetList.items, _listItem];
    dispatch({ type: actions.SET_LISTS, lists: [...listsCopy] });
  }
  return { addListItem }
}


const WORKSPACE_PADDING = 7;
const MIN_WIDTH = 300;
const MIN_HEIGHT = 200;

const LBList = ({ list, coordinates, dimensions, isFocused }) => {
  const { addListItem } = useLBListComponent();

  const [coords, updateCoordinates, portalState, togglePortal] = usePortal();
  const [aipCoords, aipUpdateCoordinates, aipPortalState, aipTogglePortal] = usePortal();
  const [listObj, setListObj] = useState({ ...list });
  const [listItems, setListItems] = useState(list.items);



  const listRef = useRef(null);
  const listHeaderRef = useRef(null);
  const listBodyRef = useRef(null);
  const listFooterRef = useRef(null);

  useEffect(() => {

    if (listBodyRef.current) {
      // calculateListHeight();
      // listBodyRef.current.scrollToBottom();
      console.log('');
    }
  }, [list.items])

  const calculateListHeight = () => {
    let headerHeight = listHeaderRef.current.getClientHeight();
    let bodyHeight = listBodyRef.current.getClientHeight();
    let footerHeight = listFooterRef.current.getClientHeight();
    listRef.current.style.height = (headerHeight + bodyHeight + footerHeight) + 'px';
  }


  useEffect(() => {
    // calculateBodyHeight();
  }, [listItems]);



  const handleMouseDownClickEvent = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    // 

    let head = e.target.closest(`.${styles.title}`);
    let panel = listRef.current;
    if (!head) return;
    if (!panel) return;
    panel.focus();


    head.classList.toggle(styles.isGrabbing);
    panel.classList.toggle(styles.drag_active);

    let isDragging = true;

    let x = e.clientX;
    let y = e.clientY;
    let z = 1000;
    let left;
    let top;

    panel.style.zIndex = z;

    const listLayoutData = () => {
      // let w = dimensions.width;
      // let h = dimensions.height;
      // let x = mousePosition.x + offset.left;
      // let y = mousePosition.y + offset.top;
      let w = panel.clientWidth;
      let h = panel.clientHeight;
      let dx = left;
      let dy = top;
      return { w, h, dx, dy }
    }
    const dragDetails = (e) => {
      // 
      let layoutData = listLayoutData();
      let zed = window.getComputedStyle(panel).zIndex;
      const listCopy = { ...list };
      listCopy.coordinates = { x: layoutData.dx, y: layoutData.dy, zed: zed };
      listCopy.zed = zed;
      listCopy.dimensions = { width: layoutData.w, height: layoutData.h };

      let detailObj = {
        evt: e,
        panel: panel,
        coordinates: { x: layoutData.dx, y: layoutData.dy, zed: z },
        dimensions: { width: layoutData.w, height: layoutData.h },
        uid: panel.dataset.uid,
        oldList: { ...list },
        list: { ...listCopy }
      }
      return detailObj;
    }


    const dragging = (e) => {
      const deltaX = e.clientX - x;
      const deltaY = e.clientY - y;

      panel.style.display = 'none';
      let elemBelow = document.elementFromPoint(e.clientX, e.clientY);
      panel.style.display = 'flex';
      if (elemBelow) {
        // console.log(elemBelow);
      }


      // let right = (panel.clientWidth + panel.offsetLeft) + 15;
      // let windowRight = window.innerWidth;

      left = panel.offsetLeft + deltaX;
      top = panel.offsetTop + deltaY;
      x = e.clientX;
      y = e.clientY;
      console.log(`panel X: ${left} panel Y: ${top} panel width: ${panel.clientWidth} panel left: ${panel.offsetLeft}`);
      if ((top < WORKSPACE_PADDING || y < WORKSPACE_PADDING) || (left < WORKSPACE_PADDING || x < WORKSPACE_PADDING)) {
        return;
      }
      panel.style.left = left + "px";
      panel.style.top = top + "px";
      panel.style.zIndex = z;
    }
    const dragStopped = (e) => {

      if (isDragging) {

        panel.classList.toggle(styles.drag_active);
        head.classList.toggle(styles.isGrabbing);



        let listDetails = dragDetails(e)

        window.removeEventListener('mousemove', dragging);
        window.removeEventListener('mouseup', dragStopped);
        isDragging = false;
        publish('dragstopped', listDetails); // subscribed to by LBWorkspace Component
        return;
      }
    }

    window.addEventListener('mousemove', dragging);
    window.addEventListener('mouseup', dragStopped);

  }, [list])

  const handleResizeMouseDownEvent = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    try {

      if (listRef.current && e.target.closest(`.${styles.resize_area}`)) {

        const panel = listRef.current;
        const resizer = e.target.closest(`.${styles.resize_area}`);

        panel.classList.toggle(styles.drag_active);

        const panelRect = panel.getBoundingClientRect();

        let x = e.pageX;
        let y = e.pageY;
        let oWidth = panelRect.width;
        let oHeight = panelRect.height;
        let nWidth;
        let nHeight;

        // let width = original_width + (e.pageX - original_mouse_x);
        // let height = original_height + (e.pageY - original_mouse_y);

        const resize = (e) => {
          let resizerLocation = resizer.classList.contains(styles.bottom_right) ? "bottom_right" : "";

          // switch (resizerLocation) {
          //   case "bottom_right":
          //     nWidth = oWidth + (e.clientX - x);
          //     nHeight = oHeight + (e.clientY - y);
          //     if (nWidth < MIN_WIDTH) {
          //       nWidth = MIN_WIDTH
          //     }
          //     if (nHeight < MIN_HEIGHT) {
          //       nHeight = MIN_HEIGHT;
          //     }
          //     break;

          //   default:
          //     break;
          // }
          nWidth = oWidth + (e.pageX - x);
          nHeight = oHeight + (e.pageY - y);
          if (nWidth < MIN_WIDTH) {
            nWidth = MIN_WIDTH
          }
          if (nHeight < MIN_HEIGHT) {
            nHeight = MIN_HEIGHT;
          }
          panel.style.width = nWidth + "px";
          panel.style.height = nHeight + "px";
          // x = e.pageX;
          // y = e.pageY;

        }

        const stopResize = (e) => {
          window.removeEventListener('mousemove', resize);
          window.removeEventListener('mouseup', stopResize);
          const listCopy = { ...list };
          let detailObj = {
            evt: e,
            panel: panel,
            coordinates: { x: panelRect.left, y: panelRect.top },
            dimensions: { width: nWidth, height: nHeight },
            uid: panel.dataset.uid,
            oldList: { ...list },
            list: { ...listCopy }
          }
          publish('resizestopped', detailObj); // subscribed to by LBWorkspaceComponent   
          return;
        }

        window.addEventListener('mousemove', resize);
        window.addEventListener('mouseup', stopResize);
      }

    } catch (error) {

    }
  }, [list])



  const handleAddListItemMenuEvent = (e) => {
    const { data, status } = e.detail;
    if (!status) return;
    const newListItem = createListItem(list.id, data.text, data.description);
    addListItem(list.id, newListItem);
  }


  // const handleSettingsButtonEvent = useCallback((e) => {
  //   e.stopPropagation();
  //   console.log(e);
  //   console.log('Settings button event');
  //   e.preventDefault();
  //   const button = e.target.closest('div');
  //   if (!button) return;
  //   updateCoordinates(button);


  //   togglePortal();
  // }, [togglePortal, updateCoordinates]);

  const handleCollapseButtonEvent = useCallback((e) => {
    e.stopPropagation();
    console.log(e);
    console.log('Collapse button event');
  }, []);

  const handleOnFocus = (e) => {
    e.stopPropagation();
    publish("onlistfocus", { evt: e, uid: list.id, target: listRef.current, style: styles.active_modal }); //LBWorkspace.js:WorkArea:useEffectOnce()
  }

  return (
    <div
      data-uid={`${list.id}`}
      data-list
      tabIndex={0}
      style={{ top: `${coordinates.y}px`, left: `${coordinates.x}px`, width: `${dimensions.width}px`, height: `${dimensions.height}px`, zIndex: `${coordinates.zed}` }}
      ref={listRef}
      className={styles.list}
      onFocus={handleOnFocus}
    >

      {portalState && (
        <PopoverPortal>
          <SettingPOMenu coords={coords} updateCoords={updateCoordinates} list={listObj}>

          </SettingPOMenu>
        </PopoverPortal>
      )}

      {aipPortalState && (
        <PopoverPortal>
          <NewListItemPOMenu handleClose={() => aipTogglePortal(false)} coords={aipCoords} updateCoords={aipUpdateCoordinates}>

          </NewListItemPOMenu>
        </PopoverPortal>
      )}


      <LBListHeader headerRef={listHeaderRef}>
        <LBListHeader.Title mouseDown={handleMouseDownClickEvent} text={list.title} />
        <WEPopup Component={LBListHeader.SettingsButton} Content={SettingsMenu} list={list} handler={handleAddListItemMenuEvent} />

        {/* <LBListHeader.SettingsButton click={handleSettingsButtonEvent} /> */}
        <LBListHeader.CollapseButton click={handleCollapseButtonEvent} />
      </LBListHeader>

      <LBListBody bodyRef={listBodyRef} listItems={list.items} />

      <LBListFooter footerRef={listFooterRef}>

        <WEPopup Component={LBListFooter.AddButton} Content={AddListItemMenu} handler={handleAddListItemMenuEvent} />

        <LBListFooter.DateLabel dateStr={'4/20/2022 04:20'} />
        <LBListFooter.ResizerArea mouseDown={handleResizeMouseDownEvent} />
      </LBListFooter>

    </div>
  );
}



export default LBList;