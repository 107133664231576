import React, { useEffect } from 'react';

import styles from '../weForm.module.css';

const Container = ({ size, children, step = "0", active = true, ...props }) => {

  useEffect(() => {

  }, [active]);

  return (
    <div data-step={step} className={`${styles.form_container} ${size ? styles[size] : ""} ${props.center ? styles.center : ""}`} style={props.style}>
      {children}
    </div>
  );
}

export default Container;