import { useState, useRef } from 'react';


const usePortal = () => {
  const [coords, setCoords] = useState();
  const [portalState, setPortalState] = useState(false);

  const anchorRef = useRef(null);


  const updateAnchor = (_ele = null) => {
    if (!_ele && !anchorRef.current) return false;

    if (_ele && anchorRef.current === null) {
      anchorRef.current = _ele;
      return _ele;
    }
    if (!_ele) return anchorRef.current;
    // if (anchorRef.current.isEqualNode(_ele)){
    //     return anchorRef.current;
    // }
    anchorRef.current = _ele;
    return _ele;
  }

  const updateCoordinates = (_anchorEle) => {
    debugger;
    let anchor = updateAnchor(_anchorEle);
    if (anchor === false) return;
    let btnRect = anchor.getBoundingClientRect();
    let left = btnRect.left - 262;
    let top = btnRect.top - 22;
    setCoords({
      left,
      top
    });
  }

  const togglePortal = () => {
    // 
    if (portalState) {
      setPortalState(false);
      return false;
    }
    setPortalState(true);
    return true;
  }

  return [coords, updateCoordinates, portalState, togglePortal];
}

export default usePortal;