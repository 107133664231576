import React from 'react';


import MultiStepManager from './components/MultiStepManager';
import Container from './components/Container';
import FormRow from './components/FormRow';

import Title from './components/Title';
import Form from './components/Form';
import Select from './components/select/Select';
import TextField from './components/textField/TextField';
import TextArea from './components/textarea/TextArea';
import Checkbox from './components/checkbox/Checkbox';
import WEButton from './components/button/Button';


const WEForm = ({ children, onSubmit }) => {
  return (
    <Form onSubmit={onSubmit}>
      {children}
    </Form>
  );
}

WEForm.MultiStepManager = MultiStepManager;
WEForm.Container = Container;
WEForm.FormRow = FormRow;

WEForm.Title = Title;
WEForm.TextField = TextField;
WEForm.TextArea = TextArea;
WEForm.Select = Select;
WEForm.Checkbox = Checkbox;
WEForm.Button = WEButton;

export default WEForm;