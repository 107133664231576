

import { useState } from 'react';
import styles from './settingsMenu.module.css';



const DetailsMenu = ({ onClick, ...props }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={`${styles.menu} ${isActive ? styles.show_menu : ""}`}>
      <div className={styles.header}>
        <button className={styles.header_btn} type={'button'} onClick={onClick}>

        </button>
        <div className={styles.title}>Details</div>
        <div className={styles.title_placeholder}></div>
      </div>

      {/* <div className={styles.search_row}>
        <input type={"search"} className={styles.search_input} />
      </div> */}
      <div className={styles.list}>
        <ul>

        </ul>
      </div>
    </div>
  );
}

export default DetailsMenu;