import React from 'react';

import styles from '../settingsMenu.module.css';

const MenuGrid = ({ children }) => {
  return (
    <div className={styles.menu_grid}>
      {children}
    </div>
  );
}

export default MenuGrid;