import { useRef } from 'react';
import LBWorkspace from '../workspace/LBWorkspace';


import styles from './workspaceManager.module.css';

const WorkspaceManager = ({ workspaces, lists }) => {
  const workspaceManagerRef = useRef(null);

  return (
    <div ref={workspaceManagerRef} className={styles.workspace_container}>
      {workspaces.length > 0 && workspaces.filter(wsf => wsf.status === 1).map(wsm => {
        return (
          <LBWorkspace key={wsm.id} listItems={lists.filter(list => list.workspace === wsm.id)} />
        )
      })}
    </div>
  );
}

export default WorkspaceManager;