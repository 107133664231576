import { useRef, useEffect } from 'react';

import styles from './weStatusDot.module.css';

const WEStatusDot = ({ statusColor }) => {
  const dotRef = useRef(null);

  useEffect(() => {


    dotRef.current.style.setProperty('--dot-color', statusColor);

  }, [statusColor])




  return (
    <div ref={dotRef} className={styles.dot}></div>
  );
}

export default WEStatusDot;