import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { DesktopContext } from '../../../contexts/DesktopContext/DesktopContext';

import { subscribe, unsubscribe } from '../../../events';
import * as eventTypes from '../../../models/EventTypes'
import * as modalTypes from '../PortalModals/modalTypes';
import * as actions from '../../../contexts/DesktopContext/actions';

import LBLeftNav from '../navigation/left/LBLeftNav';
import LeftNavMenu from '../navigation/left/menu/LeftNavMenu';
import WorkspaceManager from '../WorkspaceManager/WorkspaceManager';

import { workspaceData } from '../../data/WorkspaceData';
import { listData } from '../../data/ListData';
import { leftMenuItems } from '../../../data/SampleData';

import usePortal from '../../../portals/hooks/usePortal';
import useEffectOnce from '../../../hooks/useEffectOnce';

import styles from './desktopManager.module.css';
import LBTopNav from '../navigation/top/LBTopNav';
import List from '../../../models/List';
import Workspace from '../../../models/Workspace';
import MenuItem from '../navigation/left/menu/components/menuItem/MenuItem';
import { ListUl, Tag, WindowFullscreen } from 'react-bootstrap-icons';
import FormPortal from '../../../portals/FormPortal';
import ActionModal from '../PortalModals/ActionModal/ActionModal';
import WEModal from '../PortalModals/WEModal';
import WEPortal from '../../../portals/WEPortal';
import useDesktopContextAPI from '../../../contexts/DesktopContext/useDesktopContextAPI';

const DEFAULT_LIST_WIDTH = 250;
const DEFAULT_LIST_HEIGHT = 150;

const NEW_TAB_ID = "420";

// Helper function to create a new workspace
const createWorkspace = () => {
  const workspace = new Workspace();
  workspace.title = 'Untitled-1';
  return workspace;
}

// Helper function to create a new list
const createList = (formData, context) => {
  let w = context.clientWidth;
  let h = context.clientHeight;
  let x = w / 2 - (DEFAULT_LIST_WIDTH / 2);
  let y = h / 2 - (DEFAULT_LIST_HEIGHT / 2);

  const list = new List();
  list.name = formData.name;
  list.title = formData.title || formData.name;
  list.description = formData.description;
  list.coordinates = { x, y, zed: 1000 };
  list.dimensions = { width: DEFAULT_LIST_WIDTH, height: DEFAULT_LIST_HEIGHT };
  list.tags = formData.tags;
  list.items = [];
  return list;
}

const workspacesLeftNavClickAction = (_workspaces, _openWorkspaces, _id) => {
  let workspace = _workspaces.filter(ws => ws.id === _id);
  if (workspace.length > 0) {
    workspace = workspace[0];
  }

  let openWorkspaces = [..._openWorkspaces];

  if (_openWorkspaces.length === 0 || _openWorkspaces.filter(ows => ows.id === workspace.id).length === 0) {
    openWorkspaces = [..._openWorkspaces, workspace];
  }

  openWorkspaces.forEach(ws => {
    if (ws.status === 1) {
      ws.status = 0;
    }
    if (ws.id === _id) {
      ws.status = 1;
    }
  })

  return openWorkspaces;
}


const deactivateActiveWorkspace = (_workspaces) => {
  // const workspacesCopy = [..._workspaces];
  _workspaces.forEach(ows => {
    if (ows.status === 1) {
      ows.status = 0;
    }
  })
  return _workspaces;
}


const setWorkspaceListStatus = (_lists, _workspaceId, _status) => {
  let listsCopy = _lists.map(list => {
    if (list.workspace === _workspaceId) {
      return { ...list, status: _status ? 1 : 0 }
    }
    return { ...list, status: list.status }
  })
  return listsCopy
}


const DesktopManager = () => {
  const { addListItem } = useDesktopContextAPI();
  const [coords, updateCoordinates, portalState, togglePortal] = usePortal(); // used for the portal handling
  const { state, dispatch } = useContext(DesktopContext); // Desktop context

  // useRef variables
  const desktopAreaRef = useRef(null);


  const showListDialog = (e, result) => {

    togglePortal();
    let list = state.lists.filter(list => list.id === state.activeList)[0];
    if (result === 1) {
      console.log("MODAL OK BUTTON CLICKED");


      // check for an active tab
      // if (data.activeWorkspace !== "" && data.openWorkspaces.length !== 0) {
      //   list.workspace = data.activeWorkspace;
      //   setData({ ...data, ...{ openWorkspaces: [...data.openWorkspaces], lists: [...data.lists, list] } });
      //   return;
      // }
      // let listWorkspace = data.workspaces.filter(ws => ws.id === list.workspace);
      // if (listWorkspace.length > 0) {
      //   listWorkspace = listWorkspace[0];
      // }
      // let createNewWorkspace = false;
      // if (!createNewWorkspace) {
      //   listWorkspace.status = 1;
      //   setData({ ...data, ...{ openWorkspaces: [listWorkspace], lists: [...data.lists, list], activeWorkspace: listWorkspace.id } });
      //   return;
      // }

      const workspace = createWorkspace();
      list.workspace = workspace.id;
      workspace.status = 1;
      setData({ ...data, ...{ workspaces: [...data.workspaces, workspace], openWorkspaces: [workspace], lists: [...data.lists], activeWorkspace: workspace.id } })
      return;

    }

    console.log("MODAL CANCEL BUTTON CLICKED");
    // get the workspace that the list is part of
    let listWorkspace = state.workspaces.filter(ws => ws.id === list.workspace);

    if (listWorkspace.length > 0) {
      listWorkspace = listWorkspace[0];
    }

    let createNewWorkspace = false;
    if (!createNewWorkspace) {
      // set the workspace status to 1
      listWorkspace.status = 1;
      const listsCopy = [...state.lists];
      listsCopy.forEach(aList => {
        if (listWorkspace.id === aList.workspace) {
          aList.status = 1;
        }
      })
      dispatch({ type: actions.SET_MULTI, payload: { openWorkspaces: [listWorkspace], lists: [...listsCopy], activeWorkspace: listWorkspace.id } });
      // setData({ ...data, ...{ openWorkspaces: [listWorkspace], lists: [...listsCopy], activeWorkspace: listWorkspace.id } });
      return;
    }

  }


  const [data, setData] = useState({
    workspaces: [...workspaceData],
    openWorkspaces: [],
    lists: [...listData],
    leftNavItems: [...leftMenuItems],
    activeWorkspace: "",
    activeList: ""
  });


  // useEffectOnce sets the DesktopContext state from api call
  useEffectOnce(() => {
    dispatch({ type: actions.SET_WORKSPACES, workspaces: [...workspaceData] });
    dispatch({ type: actions.SET_LISTS, lists: [...listData] });
    dispatch({ type: actions.SET_LEFTNAV_ITEMS, leftNavItems: [...leftMenuItems] });
  })


  // useEffect for subscribing and unsubscribing to custom events
  useEffect(() => {
    subscribe(eventTypes.MENU_CLICK_NEW_LIST_REQ, handleNewListRequest);
    subscribe(eventTypes.DELETE_FROM_WORKSPACE_REQ, handleDeleteFromWorkspaceRequest);
    return () => {
      unsubscribe(eventTypes.MENU_CLICK_NEW_LIST_REQ, handleNewListRequest);
      unsubscribe(eventTypes.DELETE_FROM_WORKSPACE_REQ, handleDeleteFromWorkspaceRequest);
    }
  })



  // event handler for the click event from the left nav create new list nav item
  const handleNewListRequest = (e) => {
    const list = createList(e.detail.data, desktopAreaRef.current);
    if (state.activeWorkspace !== "" && state.openWorkspaces.length !== 0) {
      list.workspace = data.activeWorkspace;
      setData({ ...data, ...{ openWorkspaces: [...data.openWorkspaces], lists: [...data.lists, list] } });
      return;
    }
    const workspace = createWorkspace();
    list.workspace = workspace.id;
    workspace.status = 1;
    dispatch({ type: actions.SET_MULTI, payload: { workspaces: [...data.workspaces, workspace], openWorkspaces: [workspace], lists: [...data.lists, list], activeWorkspace: workspace.id } });
    return;
  }

  // event handler for when a request to delete a list from a workspace occurs from list options popover
  const handleDeleteFromWorkspaceRequest = (e) => {

    const listsCopy = [...state.lists];
    const listsUpdate = listsCopy.filter(list => list.id !== e.detail.id);
    dispatch({ type: actions.SET_LISTS, lists: [...listsUpdate] });
  }

  // event handler for the left nav menu, nav item click event
  const handleLeftNavClickEvent = useCallback((e, details) => {
    e.stopPropagation();
    e.preventDefault();


    // TODO: Check to see if list is currently in a tabbed workspace and then make workspace active

    // The "const type" is the value parent menu item clicked; The "const id" is the id of the of the sub menu item clicked
    const { type, id } = details;


    if (type === 'lists') {

      let list = state.lists.filter(list => list.id === id);

      if (list.length > 0) {
        list = list[0];
      }
      if (list.status === 1) return;



      dispatch({ type: actions.SET_ACTIVE_LIST, activeList: list.id });

      // setData({ ...data, activeList: list.id })
      togglePortal();
    }

    if (type === 'workspaces') {
      let listsCopy = state.lists.map(list => {
        if (list.workspace === id) {
          return { ...list, status: 1 }
        }
        return { ...list, status: 0 }
      })
      dispatch({ type: actions.SET_MULTI, payload: { openWorkspaces: [...workspacesLeftNavClickAction([...state.workspaces], [...state.openWorkspaces], id)], lists: [...listsCopy], activeWorkspace: id } });
    }

  }, [dispatch, state, togglePortal]);





  // event handler for handling the top nav, tab item click event
  const handleTabClickEvent = useCallback((e, id, btn = false) => {

    // copy of the state property to mutate
    const workspacesCopy = [...state.openWorkspaces];

    /*******  Tab click event handler section  *******/

    // Tab close button click event action
    const closeButtonAction = () => {
      deactivateActiveWorkspace(workspacesCopy);
      let openWorkspaces = workspacesCopy.filter(ws => ws.id !== id);
      if (openWorkspaces.length > 0) {
        openWorkspaces[0].status = 1;
      }
      let listsCopy = setWorkspaceListStatus(state.lists, id, false);
      dispatch({ type: actions.SET_MULTI, payload: { openWorkspaces: [...openWorkspaces], lists: [...listsCopy] } });
      return;
    }

    // Tab new tab click event action
    const newTabButtonAction = () => {
      const workspace = createWorkspace();
      state.openWorkspaces.forEach(ws => {
        if (ws.status === 1) {
          ws.status = 0;
        }
      })
      workspace.status = 1;
      dispatch({ type: actions.SET_OPEN_WORKSPACES, openWorkspaces: [...state.openWorkspaces, workspace] });
      return;
    }

    // Switch workspace on tab click event action
    const defaultTabButtonAction = () => {
      // Check to see if clicked tab is the current active tab and returning if the id's match
      if (state.activeWorkspace === id) return;

      // deactivate the active workspace; setting status = 0 where status = 1
      deactivateActiveWorkspace(workspacesCopy);

      // Sets the lists for the active workspace to active status
      let listsCopy = setWorkspaceListStatus(state.lists, id, true);

      // Gets the workspace of the tab that was clicked
      let selectedWorkspace = workspacesCopy.filter(workspace => workspace.id === id);

      if (selectedWorkspace.length > 0) {
        selectedWorkspace = selectedWorkspace[0];
        selectedWorkspace.status = 1; // set the status of the workspace to 1 for active workspace
        dispatch({ type: actions.SET_MULTI, payload: { workspaces: [...workspacesCopy], lists: [...listsCopy], activeWorkspace: id } });
      }
    }

    // CLOSE TAB ACTION: Check if the tab close button was clicked; returns;
    if (btn) {
      closeButtonAction();
    }

    // CREATE NEW TAB ACTION: Check if the new tab button was clicked; returns;
    if (id === NEW_TAB_ID) {
      newTabButtonAction();
    }

    // SWITCH TAB ACTION: switches the workspace to the clicked tab
    defaultTabButtonAction();

  }, [dispatch, state.lists, state.openWorkspaces, state.activeWorkspace]);


  return (
    <div className={styles.desktop_container}>

      <WEModal isOpen={portalState} handleClose={togglePortal}>
        <WEModal.ActionModal text={"How to open"} subText={"Open list in a new workspace?"} type={modalTypes.MODAL_TYPE_YES_NO} action={showListDialog} />
      </WEModal>


      {/* {portalState && (
        <FormPortal>
          <ActionModal text={"Open List Option"} subText={"Open list in a new workspace?"} type={modalTypes.MODAL_TYPE_YES_NO} icon={modalTypes.MODAL_WARN} closeAction={togglePortal} modalAction={showListDialog} />
        </FormPortal>
      )} */}


      <LBTopNav tabs={state.openWorkspaces} tabAction={handleTabClickEvent} />
      <div ref={desktopAreaRef} className={styles.desktop_area}>
        <LBLeftNav>
          <LeftNavMenu menuAction={handleLeftNavClickEvent}>
            {/* TODO: filter out subItems to prevent duplicate lists from showing */}
            <MenuItem name="lists" title="Lists" value="list" subItems={state.lists} icon={<ListUl size={18} />} />
            <MenuItem name="tags" title="Tags" value="tag" subItems={[]} icon={<Tag size={18} />} />
            <MenuItem name="workspaces" title="Workspaces" value="workspace" subItems={state.workspaces} icon={<WindowFullscreen size={18} />} />
          </LeftNavMenu>
        </LBLeftNav>
        <WorkspaceManager workspaces={state.openWorkspaces} lists={state.lists} />
      </div>
    </div>
  );
}

export default DesktopManager;