import React from 'react';

import ChevronIcon from './ChevronIcon';

import styles from '../select.module.css';




const SelectButton = ({ children }) => {
  return (
    <div className={styles.select_btn}>
      <ChevronIcon width='20' height='20' />
    </div>
  );
}

export default SelectButton;