import * as types from './modalTypes';

import styles from '../wePortal.module.css';

const YesNoActionButtons = ({ onClick }) => {
  return (
    <div className={styles.button_row}>
      <button onClick={e => onClick(e, 1)}>YES</button>
      <button onClick={e => onClick(e, 0)}>NO</button>
    </div>
  )
}

const WEActionModal = ({ text, subText, type, action, ...props }) => {

  const buttonClickEventHandler = (e, res) => {
    action && action(e, res);
  }

  return (
    <div className={styles.modal}>
      <div className={styles.header}>
        <h1>{text}</h1>
      </div>
      <div className={styles.sub_header}><h6>{subText}</h6></div>
      {type === types.MODAL_TYPE_YES_NO && <YesNoActionButtons onClick={buttonClickEventHandler} />}
    </div>
  );
}

export default WEActionModal;