import { useCallback, useImperativeHandle, useRef, useState } from 'react';

import { getClosestElementByLocalName } from '../../../../Utils';

import usePortal from '../../../../portals/hooks/usePortal';

import { GearFill, CaretDownFill, GripVertical } from 'react-bootstrap-icons';

import styles from './../list.module.css'
import PopoverPortal from '../../../../portals/PopoverPortal';
import ListItemPOMenu from '../menus/ListItemPOMenu/ListItemPOMenu';
import LBListItem from './LBListItem/LBListItem';
import { act } from '@testing-library/react';

const LBListBody = ({ bodyRef, listItems = [] }) => {
  const [coords, updateCoordinates, portalState, togglePortal] = usePortal();
  const [items, setItems] = useState([...listItems]);
  const [activeItem, setActiveItem] = useState({});

  const bodyEleRef = useRef(null);
  const listEleRef = useRef(null);

  let filteredListItems = listItems;

  useImperativeHandle(bodyRef, () => ({
    getBoundingClientRect: () => {
      return bodyEleRef.current.getBoundingClientRect();
    },
    getClientHeight: () => {
      let listHeight = 0;
      const listItems = listEleRef.current.querySelectorAll(`li.${styles.list_item}`);
      if (listItems.length > 0) {
        let element = listItems[0];
        let elementStyles = window.getComputedStyle(element);
        let marginTop = parseFloat(elementStyles.getPropertyValue("margin-top"));
        let marginBottom = parseFloat(elementStyles.getPropertyValue("margin-bottom"));
        let height = element.getBoundingClientRect().height;
        let listItemHeight = marginTop + marginBottom + marginBottom + height;
        listHeight = listItemHeight * listItems.length;
        return listHeight;
      }
      return bodyEleRef.current.getBoundingClientRect().height;
    },
    getClientWidth: () => {
      return bodyEleRef.current.getBoundingClientRect().width;
    },
    scrollToBottom: () => {
      const listItems = listEleRef.current.querySelectorAll(`li.${styles.list_item}`);
      const lastItem = listItems[listItems.length - 1];
      lastItem.scrollIntoView();
    },
    scrollToTop: () => {

    }
  }))


  const handleSettingsBtnClickEvent = useCallback((e, itemId) => {

    console.log(e);

    console.log('List item options button clicked');
    let btn = getClosestElementByLocalName(e.target, "button");
    if (btn) {
      let listItemResults = filteredListItems.filter(item => item.id === itemId);
      let listItem = "";
      if (listItemResults.length > 0) {
        listItem = listItemResults[0];
        setActiveItem({ ...listItem });
      }
      let li = btn.closest(`.${styles.list_item}`);
      if (li && li.localName.toLowerCase() === 'li') {
        li.classList.toggle(styles.show_btns);
      }

      updateCoordinates(btn);
      togglePortal();
    }



  }, []);
  const handleExpandBtnClickEvent = useCallback((e) => {
    console.log(e);
    console.log('List item expand button clicked');
    let btn = getClosestElementByLocalName(e.target, "button");
    if (btn) {
      let li = btn.closest(`.${styles.list_item}`);
      if (li && li.localName.toLowerCase() === 'li') {
        li.classList.toggle(styles.show_btns);
      }

    }
  }, [])

  const popoverMenuEventHandler = (e) => {
    console.log(e);
    let rowID = activeItem.id;
    let filteredItemsCopy = [...filteredListItems];
    let matchedItem = filteredItemsCopy.filter(item => item.id === rowID);
    let findItemIndexToDelete = (item) => item.id === rowID;
    let idx = filteredItemsCopy.findIndex(findItemIndexToDelete);
    if (idx >= 0) {
      filteredItemsCopy.splice(idx, 1);
      filteredListItems = [...filteredItemsCopy];
      // setFilteredListItems([...filteredItemsCopy]);
      setItems([...filteredItemsCopy]);
      togglePortal();
    }
  }

  return (
    <div ref={bodyEleRef} className={styles.body}>
      <div className={styles.input_container}>
        <ul ref={listEleRef}>
          {/* {filteredListItems.length === 0 && <input type={"text"} />} */}
          {filteredListItems.length > 0 && filteredListItems.map((item, index) => {
            return (
              <LBListItem key={`${item.id}-${index}`} item={item} optionBtnAction={handleSettingsBtnClickEvent} expandBtnAction={handleExpandBtnClickEvent} />
            )
          })}
          <div>Item count: {filteredListItems.length}</div>
        </ul>
      </div>

      {portalState && (
        <PopoverPortal>
          <ListItemPOMenu coords={coords} updateCoords={updateCoordinates} item={activeItem} eventHandler={(e) => popoverMenuEventHandler(e)}>

          </ListItemPOMenu>
        </PopoverPortal>
      )}


    </div>
  );
}

export default LBListBody;