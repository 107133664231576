import React from 'react';

import styles from '../portalMenu.module.css';

const FullScreenPortal = ({ children, ...props }) => {
  return (
    <div className={styles.fullscreen_portal}>
      <div className={styles.container}>
        {children}
      </div>
    </div>
  );
}

export default FullScreenPortal;