import React, { useEffect, useRef } from 'react';
import WEPortal from '../../../portals/WEPortal';
import WEActionModal from '../../../WEPortal/modals/WEActionModal';

import { CSSTransition } from 'react-transition-group';

import styles from './weModal.module.css';
import './weModal.css';
import CloseButton from '../../../WEPortal/components/buttons/CloseButton';

const WEModal = ({ children, isOpen, handleClose, ...props }) => {

  const modalRef = useRef(null);

  useEffect(() => {
    const closeOnEscKey = e => e.key === "Escape" ? handleClose() : null;
    document.addEventListener("keydown", closeOnEscKey);
    return () => {
      document.removeEventListener("keydown", closeOnEscKey);
    }
  }, [handleClose]);

  // if (!isOpen) return null;



  return (
    <WEPortal portalWrapperId='desktop_portal_modal'>
      <CSSTransition
        in={isOpen}
        timeout={{ entry: 0, exit: 500 }}
        unmountOnExit
        classNames={"we-modal__container"}
        nodeRef={modalRef}
      >

        <div className={"we-modal__container"} ref={modalRef}>
          <CloseButton className={'we-modal__close_btn'} onClick={handleClose} width={50} height={50} />

          <div className={"we-modal__content"}>{children}</div>
        </div>

      </CSSTransition>
    </WEPortal>
  );
}

WEModal.ActionModal = WEActionModal;

export default WEModal;