import React from 'react';

import styles from './addListItemMenu.module.css';
import WEForm from '../../../WEForm/WEForm';
import useWEForm from '../../../../../hooks/useWEForm';
import CheckIcon from '../../components/icons/CheckIcon';
import CancelIcon from '../../components/icons/CancelIcon';

const MENU_BUTTON_SIZE = '32';

const AddListItemMenu = ({ submit, ...props }) => {
  const { formData, registerFormInput, handleSubmit, handleFormReset } = useWEForm({
    text: '',
    description: ''
  })

  const handleFormButtonClick = (e, action) => {
    // debugger;
    switch (action) {
      case 'submit':
        submit && handleSubmit(e, submit, { type: 'close' });
        break;
      case 'cancel':
        break;
      default:

        break;
    }
  }
  return (
    <div className={styles.add_item_menu}>

      <WEForm>
        <WEForm.FormRow>
          <WEForm.TextField size="sm" style={{ width: "300px" }} {...registerFormInput('text', { required: true })} autoComplete={"off"} placeholder={'Text'} />
        </WEForm.FormRow>
        <WEForm.FormRow>
          <WEForm.TextArea size="sm" fixed={true} {...registerFormInput('description')} style={{ width: "100%", height: "150px" }} placeholder={'List description'} />
        </WEForm.FormRow>
        <WEForm.FormRow style={{ display: "flex", justifyContent: "space-around" }}>
          <button className={`${styles.menu_btn} ${styles.confirm}`} onClick={e => handleFormButtonClick(e, "submit")} title={"Create List"}>
            <CheckIcon width={'36'} height={'36'} />
          </button>
          <button className={`${styles.menu_btn} ${styles.cancel}`} onClick={e => handleFormButtonClick(e, "cancel")} title={"Cancel/Clear"}>
            <CancelIcon width={MENU_BUTTON_SIZE} height={MENU_BUTTON_SIZE} />
          </button>
          {/* <button className={`${styles.menu_btn} ${styles.confirm}`} onClick={e => handleSubmit(e, submit)} title={"Create List"}>
            <CheckIcon width={'36'} height={'36'} />
          </button>
          <button className={`${styles.menu_btn} ${styles.cancel}`} onClick={e => { }} title={"Cancel/Clear"}>
            <CancelIcon width={MENU_BUTTON_SIZE} height={MENU_BUTTON_SIZE} />
          </button> */}
        </WEForm.FormRow>
      </WEForm>

    </div>
  );
}

export default AddListItemMenu;