import { useState } from "react";




const displayCoordinates = {
  bottomxCenter: (_anchorCenter, _popupCenter, _anchorHeight) => {
    const coords = {
      top: _anchorHeight + 5,
      left: _anchorCenter - _popupCenter
    }
    return coords;
  },
  bottomLeft: (_anchorWidth, _popupWidth, _anchorHeight) => {
    const coords = {
      top: _anchorHeight + 5,
      left: _anchorWidth - _popupWidth
    }
    return coords;
  },
  bottomRight: (_anchorHeight) => {
    const coords = {
      top: _anchorHeight + 5,
      left: 0
    }
    return coords;
  },
  topCenter: () => {

  },
  topLeft: () => {

  },
  topRight: () => {

  },
  leftCenter: () => {

  },
  leftTop: () => {

  },
  leftBottom: () => {

  },
  rightCenter: () => {

  },
  rightTop: () => {

  },
  rightBottom: () => {

  }

}


const usePopoverPosition = (_position = undefined) => {
  const [coordinates, setCoordinates] = useState({
    top: 0,
    left: 0,
    width: 'unset',
    height: 'unset'
  })
  const vpRight = window.innerWidth;
  const vpBottom = window.innerHeight;

  const calculateAnchorCenters = (_anchorRect) => {
    return {
      local: _anchorRect.width / 2,
      global: _anchorRect.left + (_anchorRect.width / 2)
    }
  }

  const calculatePopupCenters = (_popupRect) => {
    return {
      local: _popupRect.width / 2,
      global: _popupRect.left + (_popupRect.width / 2)
    }
  }

  const canLeft = (_anchorRect, _popupRect) => {
    let anchorCenters = calculateAnchorCenters(_anchorRect);
    let boundsTest = anchorCenters.global - ((_popupRect.width / 2) + 5);
    return (boundsTest > 0) ? true : false;
  }
  const canRight = (_anchorRect, _popupRect) => {
    let anchorCenters = calculateAnchorCenters(_anchorRect);
    let boundsTest = anchorCenters.global + ((_popupRect.width / 2) + 5);
    return (boundsTest < vpRight) ? true : false;
  }
  const canTop = (_anchorRect, _popupRect) => {
    let boundsTest = _anchorRect.top + 5 + _popupRect.height;
    return (boundsTest > 0) ? true : false;
  }
  const canBottom = (_anchorRect, _popupRect) => {
    let boundsTest = (_anchorRect.top + _anchorRect.height) + 5 + _popupRect.height;
    return (boundsTest < vpBottom) ? true : false;
  }
  const calculatePosition = (_anchorRect, _popupRect) => {
    let anchorCenters = calculateAnchorCenters(_anchorRect);
    let popupCenters = calculatePopupCenters(_popupRect);
    let left = canLeft(_anchorRect, _popupRect);
    let right = canRight(_anchorRect, _popupRect);
    let bottom = canBottom(_anchorRect, _popupRect);
    let top = canTop(_anchorRect, _popupRect);




    let leftPosition = (left && right) ?
      anchorCenters.local - popupCenters.local : left ?
        _anchorRect.width - _popupRect.width : right ?
          0 : anchorCenters.local - popupCenters.local;
    let topPosition = bottom ?
      _anchorRect.height + 5 : -1 * _popupRect.height + 5;
    return {
      top: topPosition,
      left: leftPosition,
      width: _popupRect.width,
      height: _popupRect.height
    }
  }

  const getPopoverPosition = (_anchorRect, _popupRect) => {
    let newCoords = calculatePosition(_anchorRect, _popupRect);

    setCoordinates(newCoords);
    return newCoords;
  }

  return { getPopoverPosition, coordinates }
}

export default usePopoverPosition;