import React from 'react';

// Import loading icon component
import LoadingIcon from './iconComponents/LoadingIcon';


const StatusIndicator = ({ ...props }) => {
  return (
    <div {...props}>
      <LoadingIcon width={25} height={25} color={"#FFCF56"} />
    </div>
  );
}

export default StatusIndicator;