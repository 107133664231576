import { Gear, CaretDown, ThreeDotsVertical, Toggles, WrenchAdjustableCircle, InfoCircle, ChevronBarContract } from 'react-bootstrap-icons';

import styles from './../list.module.css';
import { forwardRef, useImperativeHandle, useRef } from 'react';



const LBListHeader = ({ headerRef, children }) => {

  const headerEleRef = useRef(null);

  useImperativeHandle(headerRef, () => ({
    getBoundingClientRect: () => {
      return headerEleRef.current.getBoundingClientRect();
    },
    getClientHeight: () => {
      return headerEleRef.current.getBoundingClientRect().height;
    },
    getClientWidth: () => {
      return headerEleRef.current.getBoundingClientRect().width;
    }
  }))

  return (
    <div ref={headerEleRef} className={styles.header}>
      {children}
    </div>
  );
}

const Title = ({ mouseDown, text }) => {
  return (
    <div className={styles.title} onMouseDown={mouseDown}>
      <h1>{text}</h1>
    </div>
  )
}
LBListHeader.Title = Title;

const SettingsButton = ({ onClick }) => {
  return (
    // <button className={styles.header_btn} onClick={onClick}><Gear size={24}/></button>
    // <button className={styles.header_btn} onClick={onClick}><Toggles size={24}/></button>
    // <button className={styles.header_btn} onClick={onClick}><InfoCircle size={24}/></button>
    <button className={styles.header_btn} onClick={onClick}><ThreeDotsVertical size={24} /></button>
    // <button className={styles.header_btn} onClick={onClick}><WrenchAdjustableCircle size={24}/></button>
  )
}
LBListHeader.SettingsButton = SettingsButton;

const CollapseButton = ({ onClick }) => {
  return (
    // <button className={styles.header_btn} onClick={onClick}><ChevronBarContract size={24}/></button>
    <button className={styles.header_btn} onClick={onClick}><CaretDown size={24} /></button>
  )
}
LBListHeader.CollapseButton = CollapseButton;

export default LBListHeader;