import React from "react";

import { AppContextProvider } from "./contexts/AppContext/AppContext";

import LBDesktop from "./desktop/LBDesktop";

import styles from './app.module.css'

function App() {
  return (
    <div className={styles.app}>
      <AppContextProvider>
        <LBDesktop />
      </AppContextProvider>
    </div>
  );
}

export default App;
