import React, { useState, useCallback } from 'react';

import { getClosestElementByLocalName } from '../../../../../Utils';

import { XLg, Trash3, GripVertical, CaretDownFill, GearFill, ToggleOn, ToggleOff, PencilSquare } from 'react-bootstrap-icons';

import styles from '../../list.module.css';
import ListSeperator from '../../../../../ListSeperator/ListSeperator';

const ListItem = ({ uid, children }) => {
  return (
    <li data-uid={uid} className={styles.list_item}>
      <div className={styles.list_item_container}>
        {children}
      </div>
    </li>
  )
}
const ListItemDetails = ({ item, isShowing }) => {
  return (
    <div className={`${styles.item_details_panel} ${isShowing ? styles.show_details : ""}`}>
      <div className={styles.details_panel_content}>
        <div className={styles.description_section}>
          <div>
            <h1>Priority Level: <span>{item.priority}</span></h1>
          </div>
          <div>
            <h1>Item:</h1>
            <p>{item.text}</p>
          </div>
          <div>
            <h1>Description:</h1>
            <p>{item.description}</p>
          </div>
          <div>
            <h1>Last Edit:</h1>
            <p>{'April 4, 2022 04:20pm'}</p>
          </div>
        </div>
        <div className={styles.options_section}>
          <div className={styles.options_panel}>
            <div className={styles.options_panel_head}>
              <h1>Options</h1>
            </div>
            <div className={styles.options_panel_options}>
              <ul>
                <li className={styles.option_panel_listitem}>
                  <div className={styles.option_listitem_content}>
                    <div className={styles.option_name}>Checkable</div>
                    <div className={`${styles.option_toggle} ${styles.on}`}><ToggleOn size={24} /></div>
                  </div>
                </li>
                <li className={styles.option_panel_listitem}>
                  <div className={styles.option_listitem_content}>
                    <div className={styles.option_name}>Option 2</div>
                    <div className={`${styles.option_toggle} ${styles.off}`}><ToggleOff size={24} /></div>
                  </div>
                </li>
                <li className={styles.option_panel_listitem}>
                  <div className={styles.option_listitem_content}>
                    <div className={styles.option_name}>Option 3</div>
                    <div className={`${styles.option_toggle} ${styles.on}`}><ToggleOn size={24} /></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ListSeperator />
      <div className={styles.details_panel_nav}>
        <button className={styles.close_color}><PencilSquare size={24} /></button>
        <button className={styles.close_color}><XLg size={24} /></button>
        <button className={styles.close_color}><Trash3 size={24} /></button>

      </div>
    </div>
  )
}
LBListItem.ListItemDetails = ListItemDetails;

const GrabIcon = () => {
  return (
    <div className={styles.grab_icon}>
      <GripVertical size={24} />
    </div>
  )
}
LBListItem.GrabIcon = GrabIcon;

const TextLabel = ({ value, onChange }) => {
  return (
    <div className={styles.list_text}>
      <input type={"text"} value={value} onChange={onChange} />
    </div>
  )
}
LBListItem.TextLabel = TextLabel;

const NavMenu = ({ children }) => {
  return (
    <div className={styles.list_button}>
      {children}
    </div>
  )
}
LBListItem.NavMenu = NavMenu;

const NavMenuButton = ({ onClick, children }) => {
  return (
    <button onClick={onClick}>
      {children}
    </button>
  )
}
LBListItem.NavMenu.NavMenuButton = NavMenuButton;



function LBListItem({ item, optionBtnAction, expandBtnAction, children = null }) {
  const [showDetails, setShowDetails] = useState(false);

  const handleSettingsBtnClickEvent = useCallback((e) => {

    let btn = getClosestElementByLocalName(e.target, 'button');
    let li = getClosestElementByLocalName(btn, 'li');
    let uid = li.dataset.uid;
    // 
    optionBtnAction(e, uid);
  }, [optionBtnAction]);
  const handleExpandBtnClickEvent = useCallback((e) => {
    let tmpBtn = getClosestElementByLocalName(e.target, 'button');
    if (tmpBtn) {
      tmpBtn.classList.toggle(styles.active);
    }

    expandBtnAction(e, item.id);
    setShowDetails(!showDetails);
  }, [expandBtnAction, item, showDetails]);

  return (
    <>
      <ListItem uid={item.id}>
        {children && children}
        {!children &&
          <>
            <GrabIcon />
            <TextLabel value={item.text} onChange={() => { }} />
            <NavMenu>
              <NavMenuButton onClick={handleSettingsBtnClickEvent}><GearFill size={24} /></NavMenuButton>
              <NavMenuButton onClick={handleExpandBtnClickEvent}><CaretDownFill size={24} /></NavMenuButton>
            </NavMenu>
          </>
        }
      </ListItem>
      <ListItemDetails item={item} isShowing={showDetails} />
    </>
  );
}

export default LBListItem;