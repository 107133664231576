import { useEffect } from "react";
import { createPortal } from "react-dom";

const FormPortal = ({ children }) => {
    const mount = document.getElementById('form_portal');
    const el = document.createElement('div');
    
    useEffect(() => {
        mount.appendChild(el);
        return () => mount.removeChild(el);
    },[el,mount])
    return createPortal(children,el);
}

export default FormPortal;