import React from 'react';

import { ListUl, CaretDown, Eye } from 'react-bootstrap-icons';
import WEStatusDot from '../../../../../WEStatusDot/WEStatusDot';

import styles from '../../leftNavMenu.module.css';

const MenuButton = ({ onClick, ...props }) => {
  return (
    <div className={styles.button_row}>
      <Button name={props.name} onClick={onClick} title={props.title} icon={props.icon} />
    </div>
  );
}

const Button = ({ name, title, icon, onClick }) => {
  return (
    <button value={name} onClick={onClick} >
      <span>
        {icon}
      </span>
      <span>
        <h4>{`${title}`}</h4>
      </span>
      <span>
        <CaretDown size={18} />
      </span>
    </button>
  )
}

const List = ({ children }) => {
  return (
    <div className={styles.list_row}>
      <ul>
        {children}
      </ul>
    </div>
  )
}

const MenuItem = ({ name, menuClick, subMenuClick, subItems, ...props }) => {



  return (
    <div name={name} className={styles.menu_item} >
      <MenuButton name={name} onClick={menuClick} icon={props.icon} {...props} />
      <List items={subItems} listAction={subMenuClick}>
        {subItems.length === 0 &&
          (
            <li className={styles.empty}>
              <h2>NO ITEMS</h2>
            </li>
          )
        }
        {subItems.length > 0 && subItems.map((item, index) => {
          let tmpItem = item;

          // 
          return (
            <li key={`${item.id}-${index}`} onClick={e => subMenuClick(e, { type: name, value: item.id, index })}>
              {item.status === 1 && <WEStatusDot statusColor={item.primaryColor} />}
              {/* {item.status === 1 && <div style={{ width: "25px", color: "red" }}>0</div>} */}
              <h2>{item.title}</h2>
              {item.status === 1 && <div className={styles.list_visible}><Eye size={16} /></div>}
            </li>
          )
        })}
      </List>
    </div>
  );
}

export default MenuItem;