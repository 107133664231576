import React from 'react';

import styles from '../weForm.module.css';

const Title = ({ label, ...props }) => {
  return (
    <div className={styles.form_title} {...props}>
      <h1>{label}</h1>
    </div>
  );
}

export default Title;