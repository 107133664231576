

class List {

  id;
  title = "";
  name = "";
  description = "";
  tags = [];
  coordinates = { x: 0, y: 0 };
  dimensions = { width: 250, heigh: 150 };
  links = [];
  items = [];
  workspace = "";

  constructor() {
    this.id = Math.random().toString(16).substring(2, 8);
  }

}

export default List;