import { useState, useEffect, useRef, forwardRef } from 'react';

import CloseIcon from './components/CloseIcon';
import SelectButton from './components/SelectButton';
import SelectInput from './components/SelectInput';
import SelectMenu from './components/SelectMenu';
import TagItem from './components/TagItem';

import styles from './select.module.css';


const Select = forwardRef(({ name, value, onChange, onReset, options, isMulti, placeholder, ...props }, ref) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showClearBtn, setShowClearBtn] = useState(false);


  const inputRef = useRef();


  useEffect(() => {

    const handler = (e) => {

      if (e.target.localName.toLowerCase() === 'input') {
        return;
      }

      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };
    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  });



  const handleInputClick = (e) => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {

    if (!value || value.length === 0) {
      if (inputRef.current !== null && inputRef.current !== undefined) {
        // inputRef.current.style.color = 'rgba(255, 250, 250, 0.233)';        
      }

      return placeholder;
    }

    if (isMulti) {
      return (
        <div className={styles.tags}>
          {value.map((option) => (
            <TagItem key={option.value} label={option.name} onClick={(e) => onTagRemove(e, option)} />
          ))}
        </div>
      );
    }

    if (inputRef.current !== null && inputRef.current !== undefined) {
      inputRef.current.style.color = 'white';
    }

    return value[0]?.label ? value[0]?.label : placeholder;
  };


  const removeOption = (option) => {
    return value.filter((o) => o.value !== option.value);
  };
  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValue = [...removeOption(option)];
    // setSelectedValue(newValue);
    onChange(newValue);
  };

  const onItemClick = (e, id) => {

    let newValue;
    let option = options.filter(x => x.id === id)[0];
    // 

    if (isMulti) {
      //
      if (value.findIndex((o) => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...value, option];
      }
      // setSelectedValue([...newValue]);
      onChange({ target: { value: [...newValue] } });
      return;
    } else {
      newValue = option;
    }

    let selected = value.filter(value => value.value === newValue.value);

    if (selected.length > 0) {
      // setSelectedValue([]);   
      onChange({ target: { value: [...value, newValue] } });
      return;
    }
    setShowClearBtn(true)
    // setSelectedValue([newValue]);
    onChange({ target: { value: [newValue] } });
    setShowMenu(false);
    getDisplay();
  };
  const onUpdate = (e) => {

    console.log(e);
  }
  const isSelected = (option) => {
    // 
    if (isMulti) {
      return value.filter((o) => o.value === option.value).length > 0;
    }
    if (!value) {
      return false;
    }
    return value[0]?.value === option.value;
  };

  const handleResetEvent = (e, name) => {
    setShowClearBtn(false);
    onReset && onReset(e, name)
  }

  return (
    <div ref={inputRef} className={styles.container}>
      <div ref={ref} className={styles.select_input} onClick={handleInputClick}>
        <SelectInput value={getDisplay()} />
        {showClearBtn && <CloseIcon width='26' height='26' color="rgba(255, 0, 0, 0.5)" onClick={e => handleResetEvent(e, name)} />}
        <SelectButton />
      </div>

      {showMenu && (
        <SelectMenu isSearchable={props.isSearchable} options={options} onUpdate={onUpdate} onClick={onItemClick} isSelected={isSelected} isEditable={props.editable} />
      )}

    </div>
  );
})

export default Select;