import React, { useCallback, useState } from 'react';



import styles from './leftNav.module.css';

const LeftNav = ({ isVisible, children }) => {
  return (
    <div className={`${styles.left_nav_container} ${isVisible ? "" : styles.hidden}`}>
      <div className={styles.left_nav}>
        {children}
      </div>
    </div>
  )
}

const CloseButton = ({ onClick }) => {
  return (
    <div className={styles.button_container}>
      <button className={styles.close_button} onClick={onClick}>
      </button>
    </div>
  )
}

function LBLeftNav({ children }) {
  const [isVisible, setIsVisible] = useState(true);




  const handleCloseButtonClick = useCallback((e) => {
    console.log(e);
    console.log("Left nav close button click event");
    setIsVisible(!isVisible);
  }, [isVisible]);

  return (
    <LeftNav isVisible={isVisible}>
      <CloseButton onClick={handleCloseButtonClick} />
      {children}
    </LeftNav>
  );
}

export default LBLeftNav;