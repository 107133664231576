import React, { useState, useEffect, useRef } from 'react';


import styles from '../select.module.css'
import AddIcon from './AddIcon';

/**
 * 
 * @param {Function} onChange 
 * @param {String} value 
 * @param {String} placeholder 
 * @returns component that contains a search box for searching menu items options
 */
const SearchBox = ({ onChange, value, placeholder, shouldShow }) => {

  useEffect(() => {

    inputRef.current.focus();

  }, [shouldShow])

  const inputRef = useRef(null)

  return (
    <div className={styles.search_box}>
      <input name="searchTxt" ref={inputRef} onChange={onChange} value={value} /*ref={searchRef}*/ placeholder={placeholder} />
    </div>
  )
}
SelectMenu.SearchBox = SearchBox;

/**
 * 
 * @param {Object} option 
 * @param {Function} isSelected 
 * @param {Function} onClick 
 * @returns component that is a menu option
 */
const DefaultMenuOption = ({ value, name, isSelected, onClick }) => {

  const handleOnClickEvent = (e) => {
    let name = e.target.getAttribute('name');
    onClick && onClick({ target: { value: name } });
  }

  return (
    <div name={name} value={value} className={`${styles.select_item} ${isSelected && styles.selected}`} onClick={(e) => handleOnClickEvent(e)}>
      {value}
    </div>
  )
}
SelectMenu.DefaultOption = DefaultMenuOption;

/**
 * 
 * @param {Function} onClick 
 * @param {React.children} children 
 * @returns returns an add button row component
 */
const ButtonRow = ({ onClick, children }) => {
  return (
    <div className={`${styles.add_item}`} onClick={onClick}>
      {children}
    </div>
  )
}
SelectMenu.ButtonRow = ButtonRow;






/**
 * 
 * @param {Boolean} isSearchable 
 * @param {Array} options 
 * @param {Function} onItemClick 
 * @param {Function} isSelected 
 * @param {Boolean} editable 
 * @param {React.children} children 
 * @returns 
 */
function SelectMenu({ options, onClick, onUpdate, isEditable = false, ...props }) {
  const [searchValue, setSearchValue] = useState('');
  const [showMenuPanel, setShowMenuPanel] = useState(false);


  const searchRef = useRef();
  const menuRef = useRef();

  useEffect(() => {
    setSearchValue('');
    if (props.showing && props.isSearchable) {
      searchRef.current.focus();
    }
  }, [props.showing, props.isSearchable]);


  const getOptions = () => {

    if (!searchValue) {
      return options;
    }
    const retOptions = [{ id: 420, value: "default" }, ...options,];
    return retOptions.filter(
      (option) =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleClick = (e) => {
    console.log(e);
    e.stopPropagation();
    e.preventDefault();
    onUpdate(e);
    if (showMenuPanel) {
      menuRef.current.style.overflow = "auto";
    } else {
      menuRef.current.style.overflow = "hidden";
    }
    setShowMenuPanel(!showMenuPanel);

  }

  return (
    <div ref={menuRef} className={styles.menu}>

      {props.isSearchable && (
        <SearchBox onChange={onSearch} placeholder='Enter search term' shouldShow={props.showing} />
      )}

      {getOptions().map((option, index) => {
        if (index === 0) {
          if (option.id === 420) {
            return (
              <DefaultMenuOption key={"placeholder" + index} name={"default"} value={props.placeholder} isSelected={false} onClick={e => onClick(e, "default")} />
            )
          }
        }
        return (
          <DefaultMenuOption key={option.id} name={option.value} value={option.label} isSelected={props.isSelected(option)} onClick={e => onClick(e, option.id)} />
        )
      }

      )}

      {isEditable && (
        <ButtonRow>
          <button className={styles.btn_add_item} onClick={handleClick}>
            <AddIcon width='20' height='20' />
          </button>
          {showMenuPanel && (
            <div className={styles.slide_panel}>
              <div className={styles.input_row}>

                <input />

              </div>
            </div>
          )}
        </ButtonRow>)}
    </div>
  );
}

export default SelectMenu;