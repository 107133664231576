import React from 'react';
import * as eventTypes from '../../../../../models/EventTypes';

import { publish } from '../../../../../events';

import { ChevronRight, PencilSquare, Trash3 } from 'react-bootstrap-icons';


import ListSeperator from '../../../../../ListSeperator/ListSeperator';

import styles from './listItemPOMenu.module.css';

const Menu = ({ coords, children }) => {
  return (
    <div className={styles.listitem_po_menu} style={{ ...coords }}>
      {children}
    </div>
  )
}


const Header = ({ children }) => {
  return (
    <div className={styles.header}>
      {children}
    </div>
  )
}

const ListItemPOMenu = ({ coords, item, eventHandler }) => {

  const handleDeleteBtnClick = (e) => {

    console.log(e);
    console.log("Handle list item delete event");
    let retObj = {
      event: e,
      type: "delete",
      item
    }
    eventHandler(retObj);
    publish(eventTypes.DELETE_FROM_WORKSPACE_REQ, item); // Subscribers: DestopManager.js
    // publish('deletelistitem', item);

  }

  return (
    <Menu coords={coords}>
      <Header>
        <h1>{item.text}</h1>
      </Header>
      <ListSeperator />
      <div className={styles.menu_body}>
        <div className={styles.menu_list}>
          <ul>
            <li name="details">
              <span>Details</span>
              <span>
                <ChevronRight size={18} />
              </span>
            </li>
            <li name="links">
              <span>Links</span>
              <span>
                <ChevronRight size={18} />
              </span>
            </li>
            <li name="settings">
              <span>Settings</span>
              <span>
                <ChevronRight size={18} />
              </span>
            </li>
          </ul>
        </div>
      </div>
      <ListSeperator />
      <div className="listSeperator_seperator__tZqMg"></div>
      <div className={styles.menu_bottom_nav}>
        <button name="edit_btn" className={styles.nav_btn} type="button" title='Edit list details'>
          <PencilSquare size={24} />
        </button>
        <button name="trash_btn" className={styles.nav_btn} type="button" title='Delete list' onClick={handleDeleteBtnClick}>
          <Trash3 size={24} />
        </button>
      </div>
    </Menu>
  );
}

export default ListItemPOMenu;