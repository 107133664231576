import React from 'react';

import styles from '../../leftNavMenu.module.css';

const MenuBody = ({ children }) => {
  return (
    <div className={styles.bottom}>
      <div className={styles.left_nav_menu_wrapper}>
        {children}
      </div>
    </div>
  );
}

export default MenuBody;