import React from 'react';
import { PlusLg, Search } from 'react-bootstrap-icons';

import styles from '../../leftNavMenu.module.css';

const Section = ({ className, children }) => {
  return (
    <div className={`${styles.top_sections} ${className}`}>
      {children}
    </div>
  )
}

const NewButton = ({ onClick }) => {
  return (
    <button onClick={onClick}>
      <span><PlusLg size={22} />
      </span><span>New List</span>
    </button>
  )
}

const SearchField = ({ children }) => {
  return (
    <div className={styles.search_input_wrapper}>
      <div className={styles.search_input_btn}>
        <button>
          <Search size={18} />
        </button>
      </div>
      <div className={styles.search_input}>
        <input type="text" className={styles.left_nav_search_input} />
      </div>
    </div>
  )
}

const TopMenu = ({ buttonAction, ...props }) => {
  return (
    <div className={styles.top}>

      <Section className={styles.create_list_btn_wrapper}>
        <NewButton onClick={buttonAction} />
      </Section>
      <Section className={styles.left_nav_search_wrapper}>
        <SearchField />
      </Section>

    </div>
  );
}

export default TopMenu;