import { Children, cloneElement, useState, useRef } from 'react';
import Popup from './components/Popup';


import { getDOMRectObj, getHiddenDOMRectObj } from './DOMUtils';


import styles from './wePopup.module.css';

import usePopoverPosition from './usePopoverPosition';

const defaultOptions = {
  position: 'center',
  arrow: false
}

// TODO: add inside flag
// TODO: add support for absolute position child by accepting a style prop that mirrors the child css styles

const WEPopup = ({ Component, Content, handler, ...props }) => {
  // const childArray = Children.toArray(children);
  const [isVisible, setIsVisible] = useState(false);
  const { getPopoverPosition, coordinates } = usePopoverPosition();

  let w = (coordinates.width === 'unset') ? 'unset' : coordinates.width + 'px';
  let h = (coordinates.height === 'unset') ? 'unset' : coordinates.height + 'px';


  const popupRef = useRef(null);

  const onClickHandler = (e, handler) => {
    // debugger;
    e.stopPropagation();
    e.preventDefault();
    let anchorEleRect = getDOMRectObj(e.currentTarget);
    let popupEleRect = getHiddenDOMRectObj(popupRef.current, styles.show, true);
    getPopoverPosition(anchorEleRect, popupEleRect);
    setIsVisible(!isVisible);
  }

  const onContentAction = (e, data, status, args) => {
    if (!status) return;
    let retEvtDetails = {
      data,
      status,
      args
    }
    setIsVisible(false);
    handler && handler({ detail: retEvtDetails });
  }

  return (
    <div style={{ position: 'relative', overflow: "visible" }}>
      <Popup ref={popupRef} show={isVisible} handleClose={setIsVisible} top={coordinates.top} left={coordinates.left} width={coordinates.width} height={coordinates.height}>
        <Content submit={onContentAction} {...props} />
      </Popup>
      <Component onClick={(e) => onClickHandler(e)} />
    </div>
  );
}

export default WEPopup;