export const listItems = {
  veggie_list: [
    {
      id: "faa6f3",
      list: '2a2c18',
      priority: 1,
      rank: 1,
      type: 0,
      status: 0,
      text: "corn",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    {
      id: "fdd15a",
      list: '2a2c18',
      priority: 2,
      rank: 2,
      type: 0,
      status: 0,
      text: "rice",
      description: "Accumsan sit amet nulla facilisi morbi tempus."
    },
    {
      id: "953f65",
      list: '2a2c18',
      priority: 3,
      rank: 3,
      type: 0,
      status: 0,
      text: "beans",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    }
  ],
  supply_list: [
    {
      id: "65ec0d",
      list: '6e9289',
      priority: 1,
      rank: 1,
      type: 0,
      status: 0,
      text: "Dirt",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    {
      id: "07fc96",
      list: '6e9289',
      priority: 2,
      rank: 2,
      type: 0,
      status: 0,
      text: "Tomato cages",
      description: "Accumsan sit amet nulla facilisi morbi tempus."
    },
    {
      id: "79d38c",
      list: '6e9289',
      priority: 3,
      rank: 3,
      type: 0,
      status: 0,
      text: "Fertilizer",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    }
  ],
  watchlist: [
    {
      id: "84ca93",
      list: '6d3e84',
      priority: 1,
      rank: 1,
      type: 0,
      status: 0,
      text: "Top gun",
      description: "Vitae tempus quam pellentesque nec nam aliquam sem et."
    },
    {
      id: "d39bec",
      list: '6d3e84',
      priority: 4,
      rank: 2,
      type: 0,
      status: 0,
      text: "Harry potter",
      description: "Viverra tellus in hac habitasse. Risus viverra adipiscing at in tellus integer feugiat scelerisque."
    },
    {
      id: "97e391",
      list: '6d3e84',
      priority: 2,
      rank: 3,
      type: 0,
      status: 0,
      text: "Die Hard",
      description: "Congue eu consequat ac felis."
    },
    {
      id: "2cb39c",
      list: '6d3e84',
      priority: 5,
      rank: 4,
      type: 0,
      status: 0,
      text: "The Matrix",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    {
      id: "18be19",
      list: '6d3e84',
      priority: 3,
      rank: 5,
      type: 0,
      status: 0,
      text: "Welcome to america",
      description: "Ullamcorper malesuada proin libero nunc consequat."
    }
  ]

}

export const listData = [
  {
    id: "2a2c18",
    workspace: "256ef9",
    name: "test_list",
    title: "Veggie List",
    description: "",
    tagString: "",
    primaryColor: "#F3B61F",
    coordinates: { x: 300, y: 100 },
    dimensions: { width: 400, height: 250 },
    zed: 1,
    items: listItems.veggie_list,
    status: 0
  },
  {
    id: "6e9289",
    workspace: "256ef9",
    name: "test_list",
    title: "Supply List",
    description: "",
    tagString: "",
    primaryColor: "#F3B61F",
    coordinates: { x: 725, y: 100 },
    dimensions: { width: 400, height: 250 },
    zed: 1,
    items: listItems.supply_list,
    status: 0
  },
  {
    id: "6d3e84",
    workspace: "fd6ddc",
    name: "test_list",
    title: "Watchlist",
    description: "",
    tagString: "",
    primaryColor: "#20BF55",
    coordinates: { x: 725, y: 100 },
    dimensions: { width: 400, height: 250 },
    zed: 1,
    items: listItems.watchlist,
    status: 0
  }
]