import { useContext } from "react"
import { DesktopContext } from "./DesktopContext"
import * as actions from './actions';


const useDesktopContextAPI = () => {
  const { state, dispatch } = useContext(DesktopContext);

  const setLists = (_lists) => {
    const newListsCopy = [..._lists];
    dispatch({ type: actions.SET_LISTS, lists: [...newListsCopy] });
  }



  return { state }
}

export default useDesktopContextAPI;