import React, { Children, cloneElement, useRef } from 'react';
import * as eventTypes from '../../../../../models/EventTypes';

import usePortal from '../../../../../portals/hooks/usePortal';
import FormPortal from '../../../../../portals/FormPortal';

import { publish } from '../../../../../events';


import styles from './leftNavMenu.module.css';
import CreateListPortalForm from '../../../PortalMenus/CreateListPortalForm/CreateListPortalForm';
import TopMenu from './components/topMenu/TopMenu';
import MenuItem from './components/menuItem/MenuItem';
import MenuBody from './components/menuBody/MenuBody';
import MenuFooter from './components/menuFooter/MenuFooter';

const subMenuAction = (e) => {
  return (type) => {
    if (type === "list") {
      return (id) => {
        console.log("subMenuAction:ListAction:List subMenuAction invoked");
        publish(eventTypes.MENU_CLICK_LIST_ITEM, { rootEvent: e, type, id });
      }
    }
    if (type === "workspace") {
      return (id) => {
        console.log("subMenuAction:ListAction:Workspace subMenuAction invoked");
        publish(eventTypes.MENU_CLICK_WORKSPACE_ITEM, { rootEvent: e, type, id });
      }
    }
  }
}


const updateMenuStatus = (_menuItems, _status) => {
  let currentItemName = "";
  for (let i = 0; i < _menuItems.length; i++) {
    const element = _menuItems[i];
    if (element.classList.contains(`${styles.show}`)) {
      currentItemName = element.getAttribute('name');
      element.classList.remove(`${styles.show}`);
      break;
    }
  }

  for (let i = 0; i < _menuItems.length; i++) {
    const element = _menuItems[i];
    let tmpName = element.getAttribute('name');
    if (tmpName.toLowerCase() === _status.toLowerCase() && currentItemName.toLowerCase() !== tmpName.toLowerCase()) {
      element.classList.add(`${styles.show}`);
      return element;
    }
  }

}

const LeftNavMenu = ({ children, menuAction }) => {
  const [coords, updateCoordinates, portalState, togglePortal] = usePortal();
  const childArray = Children.toArray(children);


  const menuRef = useRef(null);


  const handleMenuClickEvent = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    const menu = menuRef.current;
    const menuItems = menu.children;
    updateMenuStatus(menuItems, type);
  }

  const handleSubMenuClickEvent = (e, details) => {
    e.preventDefault();
    e.stopPropagation();
    let type = details.type;
    let id = details.value;
    // subMenuAction(e)(type)(id);
    menuAction && menuAction(e, { type, id });
  }

  const handleCreateNewListEvent = (e) => {
    console.log(e);
    console.log("Create new list event");
    e.preventDefault();
    e.stopPropagation();
    togglePortal();
  }

  return (
    <div className={styles.left_nav_menu}>

      {portalState && (
        <FormPortal>
          <CreateListPortalForm closeAction={() => togglePortal()}>

          </CreateListPortalForm>
        </FormPortal>
      )}

      <TopMenu buttonAction={handleCreateNewListEvent} />
      <MenuBody>
        <div ref={menuRef} className={styles.menu}>
          {/* {children} */}
          {Children.map(childArray, (child, index) => {
            let props = { ...child.props };
            let menuClick = (e) => handleMenuClickEvent(e, props.title);
            let subMenuClick = handleSubMenuClickEvent;
            return cloneElement(child, { ...props, ...{ menuClick, subMenuClick } }, null);
          })}
          {/* {menuItems.length > 0 && menuItems.map((item, index) => {
            return (
              <MenuItem key={item.id} name={item.value} title={item.title} subItems={item.subItems} menuClick={e => handleMenuClickEvent(e, item.value)} subMenuClick={handleSubMenuClickEvent} icon={item.icon} />
            )
          })} */}

        </div>
      </MenuBody>
      <MenuFooter />
    </div>
  );
}

export default LeftNavMenu;