import React from 'react';

import { publish } from '../../../../events';

import { PersonCircle, WindowStack, XCircleFill, PlusLg } from 'react-bootstrap-icons';


import styles from './topNav.module.css';

const NavButton = ({ onClick, children, title }) => {
  return (
    <button title={title} className={styles.nav_btn} onClick={onClick}>
      {children}
    </button>
  )
}

function LBTopNav({ tabs, tabAction }) {

  const handleTabClickEvent = (e, id) => {
    console.log("LBTopNav:Event:handleTabClickEvent");

    let tabsCopy = [...tabs];
    let clickedTab = tabsCopy.filter(tab => tab.id === id);
    if (clickedTab.length > 0) {
      clickedTab = clickedTab[0];
    }

    let target = e.target.closest('button');
    if (target) {
      if (target.localName.toLowerCase() === 'button') {
        console.log(clickedTab.title + ' tab close button clicked');
        tabAction && tabAction(e, id, true);
        return;
      }
    }
    tabAction && tabAction(e, id);
    console.log(`${clickedTab.title} tab was clicked. ${clickedTab.id} is active tab id`)
    console.log(e);
  }

  return (
    <div className={styles.top_nav}>
      <div className={styles.nav_fill}></div>
      <div className={styles.nav_tab_container}>
        {tabs.length > 0 && tabs.map((tab, index) => {
          // debugger;
          if (tab.status === 1) {
            return (
              <div key={tab.id} className={`${styles.nav_tab} ${styles.active}`} onClick={e => handleTabClickEvent(e, tab.id)}>
                <div>{tab.title}</div>
                <button><XCircleFill /></button>
              </div>
            )
          }
          return (
            <div key={tab.id} className={`${styles.nav_tab}`} onClick={e => handleTabClickEvent(e, tab.id)}>
              <div>{tab.title}</div>
              <button><XCircleFill /></button>
            </div>
          )
        })}
        <div className={`${styles.nav_tab} ${styles.nav_add_tab}`} onClick={e => handleTabClickEvent(e, '420')}>
          <div><PlusLg size={20} /></div>
        </div>
      </div>
      <div className={styles.nav_btn_container}>
        <NavButton title={"Arrange lists"}><WindowStack size={24} /></NavButton>
        <NavButton title={"Profile menu"}><PersonCircle size={24} /></NavButton>
      </div>
    </div>
  );
}

export default LBTopNav;