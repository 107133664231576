import { ListUl, Tag, WindowFullscreen } from "react-bootstrap-icons";


const listsMenuItems = [
  {
    id: "2a2c18",
    title: "Veggie List",
    value: "2a2c18"
  },
  {
    id: "6d3e84",
    title: "Watchlist",
    value: "6d3e84"
  },
]

const workspaceMenuItems = [
  {
    id: "256ef9",
    name: "garden_workspace",
    title: "Garden Project",
    status: 1
  },
  {
    id: "fd6ddc",
    name: "movie_workspace",
    title: "Movie Lists",
    status: 0
  }
]

export const leftMenuItems = [
  {
    id: 'cdc25e',
    name: "lists",
    title: "Lists",
    value: "list",
    subItems: [...listsMenuItems],
    icon: <ListUl size={18} />,
    action: () => { }
  },
  {
    id: '24cd1b',
    name: "tags",
    title: "Tags",
    value: "tag",
    subItems: [],
    icon: <Tag size={18} />
  },
  {
    id: 'fb3f66',
    name: "workspace",
    title: "Workspaces",
    value: "workspace",
    subItems: [...workspaceMenuItems],
    icon: <WindowFullscreen size={18} />,
    action: () => { }
  }
]

export const tags = [
  {
    id: 'be93f6',
    title: 'Projects',
    name: 'projects',
    color: '#DB504A'
  },
  {
    id: '95dcd7',
    title: 'Trips',
    name: 'trips',
    color: '#E3B505'
  },
  {
    id: '6be044',
    title: 'Work',
    name: 'work',
    color: '#56A3A6'
  },
  {
    id: '4ca805',
    title: 'School',
    name: 'school',
    color: '#5E4AE3'
  },
]