import React, { forwardRef, useRef } from 'react';
import ClearButton from '../baseComponents/ClearButton';
import StatusIndicator from '../baseComponents/StatusIndicator';

import styles from './textArea.module.css';

const inputHeight = {
  default: "58px"
}


const TextArea = forwardRef(({ name, value, onChange, onReset, status = "idle", ...props }, ref) => {

  const inputRef = useRef(null);


  const inputChanged = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.fixed === true) {
      onChange(e);
      return;
    }

    let target = e.target;
    if (props?.style?.height) {
      if (inputRef.current === null) {
        inputRef.current = target;
        target.style.height = props.style.height;
        return;
      }
    }
    if (target.localName.toLowerCase() === "textarea") {
      if (inputRef.current === null) {
        inputRef.current = target;
        inputHeight.default = target.clientHeight + "px";
        inputHeight.current = target.clientHeight + "px";
      }
      let textArea = inputRef.current;
      if (textArea.value === "") {
        textArea.style.height = inputHeight.default;
        onChange(e);
        return;
      }
      textArea.style.height = "auto";
      textArea.style.height = `calc(${textArea.scrollHeight}px + 0.5em)`;
      onChange(e)
    }
  }

  return (
    <div className={styles.container}>
      {(status === "busy") && <StatusIndicator width={25} height={25} className={styles.status} />}
      {(status === "idle" && value !== "") && <ClearButton className={styles.close_btn} width={25} height={25} onClick={(e) => onReset(e)} />}

      <textarea
        ref={ref}
        id={`${name}-${Math.floor(Math.random() * 1000)}`}
        className={`${styles.text_field} ${props.size ? styles[props.size] : ""}`}
        maxLength={props.maxLen ? props.maxLen : "250"}
        value={value}
        onChange={(e) => inputChanged(e)}
        {...props}
        disabled={props.disabled}
        placeholder={props.placeholder ? props.placeholder : `Enter ${props.name}`}
        autoComplete="off"
      />


      <label className={styles.label}>
        {props.placeholder}
      </label>
    </div>
  )
})

export default TextArea;