import React from 'react';
import { XLg } from 'react-bootstrap-icons';

import styles from '../portalMenu.module.css';

const PortalCloseButton = ({ onClick, ...props }) => {
  return (
    <button className={styles.portal_close_btn} onClick={onClick}>
      <XLg size={36} />
    </button>
  );
}

export default PortalCloseButton;