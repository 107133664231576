import React, { useRef, useState, useContext, Children, cloneElement, useEffect } from 'react';
import * as desktopAction from '../../../contexts/DesktopContext/actions';

import { DesktopContext } from '../../../contexts/DesktopContext/DesktopContext';



import { subscribe, unsubscribe } from '../../../events';


import LBList from '../List/LBList';

import { listData } from '../../data/ListData';

import styles from './workspace.module.css';

const getDimensions = (ele) => {
  let w = ele.clientWidth;
  let h = ele.clientHeight;

  return { width: w, height: h };
}
const calculateCenter = (width, height) => {
  let x = width / 2;
  let y = height / 2;
  return { x, y };
}




const WorkArea = ({ children }) => {
  const workAreaRef = useRef(null);

  return (
    <div ref={workAreaRef} className={styles.work_area}>
      {children}
    </div>
  )
}

const LBWorkspace = ({ listItems }) => {
  const { state, dispatch } = useContext(DesktopContext);
  const [filteredLists, setFilteredLists] = useState(listItems);
  const containerRef = useRef(null);
  const zedRef = useRef(1);

  const itemsArr = listItems;

  const handleListPosChangeEvent = (e) => {
    console.log(e);

    const listsCopy = [...state.lists];
    const listid = e.detail.uid;

    // HANDLE THE X,Y LIST POSITION CHANGE
    if (e.type.toLowerCase() === 'dragstopped') {
      console.log('List drag change event');
      const { x, y } = e.detail.coordinates;
      const droppedList = listsCopy.filter(l => l.id === listid)[0];
      let calculatedZed = zedRef.current + 1;
      let tmpCoordinates = { x, y, zed: calculatedZed };
      zedRef.current = calculatedZed;
      droppedList.coordinates = tmpCoordinates;
      dispatch({ type: desktopAction.SET_LISTS, lists: [...listsCopy] });
      return;
    }

    // HANDLE WIDTH,HEIGHT LIST CHANGE
    if (e.type.toLowerCase() === 'resizestopped') {
      console.log('List resize change event');
      const { width, height } = e.detail.dimensions;
      const resizedList = listsCopy.filter(l => l.id === listid)[0];
      let tmpDimensions = { width, height };
      resizedList.dimensions = tmpDimensions;
      dispatch({ type: desktopAction.SET_LISTS, lists: [...listsCopy] });
      return;
    }
  }

  // const handleListResizeChangeEvent = (e) => {
  //   let tmp = e;
  //   debugger;
  //   console.log(e.detail.list)
  //   dispatch({ type: desktopAction.SET_LISTS, lists: retArr })
  // }

  const handleFocusEvent = (e) => {
    let tmp = e.currentTarget.nodeName;

    if (tmp === "#document") return;

    debugger;
    let listId = e.detail.uid;
    const listsCopy = [...state.lists];
    let listCopy = listsCopy.filter(list => list.id === listId);
    if (listCopy.length > 0) {
      listCopy = listCopy[0];
    }
    e.target.style.zIndex = zedRef.current++;
    listCopy.status = 1;
    listCopy.coordinates = { ...listCopy.coordinates };
    dispatch({ type: desktopAction.SET_LISTS, lists: [...listsCopy] });
    dispatch({ type: desktopAction.SET_ACTIVE_LIST, activeList: listCopy.id });
  }

  useEffect(() => {
    subscribe('onlistfocus', handleFocusEvent);
    subscribe('dragstopped', handleListPosChangeEvent); // published from LBList component  
    subscribe('resizestopped', handleListPosChangeEvent);
    return () => {
      unsubscribe('onlistfocus', handleFocusEvent);
      unsubscribe("dragstopped", handleListPosChangeEvent);
      unsubscribe("resizestopped", handleListPosChangeEvent)
    }

  })

  useEffect(() => {

    setFilteredLists([...listItems])
  }, [listItems])



  return (
    <div ref={containerRef} className={styles.workspace}>
      <WorkArea>
        {filteredLists.map((list, index) => {
          let tmp = list;

          return (
            <LBList
              style={{ zIndex: 1 }}
              key={`${list.id}`}
              list={{ ...list }}
              coordinates={list.coordinates}
              dimensions={list.dimensions}
              isFocused={list.status === 1 ? true : false}
            />
          );
        })}
      </WorkArea>
    </div>
  );
}

export default LBWorkspace;