import React from 'react';

import styles from '../weForm.module.css';

const Form = ({ children, onSubmit }) => {
  return (
    <form className={styles.form_element} onSubmit={onSubmit}>
      {children}
    </form>
  );
}

export default Form;