import React, { useImperativeHandle, useRef } from 'react';
import { PlusLg } from 'react-bootstrap-icons';

import styles from './../list.module.css'

const LBListFooter = ({ footerRef, children }) => {

  const footerEleRef = useRef(null);

  useImperativeHandle(footerRef, () => ({
    getBoundingClientRect: () => {
      return footerEleRef.current.getBoundingClientRect();
    },
    getClientHeight: () => {
      return footerEleRef.current.getBoundingClientRect().height;
    },
    getClientWidth: () => {
      return footerEleRef.current.getBoundingClientRect().width;
    }
  }))

  return (
    <div ref={footerEleRef} className={styles.footer}>
      {children}
      {/* <div className={styles.options}><Toggles size={18}/></div> */}
    </div>
  );
}

const AddButton = ({ onClick }) => {
  return (
    <button className={styles.add_button} onClick={onClick}><PlusLg size={18} /></button>
  )
}
LBListFooter.AddButton = AddButton;

const DateLabel = ({ dateStr }) => {
  return (
    <div className={styles.last_update}><h6>Last updated:<span>{dateStr}</span></h6></div>
  )
}
LBListFooter.DateLabel = DateLabel;

const ResizerArea = ({ mouseDown }) => {
  return (
    <div className={`${styles.resize_area} ${styles.bottom_right}`} onMouseDown={mouseDown}>
      <svg height="12" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
        <path d="m14.228 16.227a1 1 0 0 1 -.707-1.707l1-1a1 1 0 0 1 1.416 1.414l-1 1a1 1 0 0 1 -.707.293zm-5.638 0a1 1 0 0 1 -.707-1.707l6.638-6.638a1 1 0 0 1 1.416 1.414l-6.638 6.638a1 1 0 0 1 -.707.293zm-5.84 0a1 1 0 0 1 -.707-1.707l12.477-12.477a1 1 0 1 1 1.415 1.414l-12.478 12.477a1 1 0 0 1 -.707.293z" fill="currentColor" />
      </svg>
    </div>
  );
}
LBListFooter.ResizerArea = ResizerArea;

export default LBListFooter;