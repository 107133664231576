

class Workspace {
  id = '';
  name = ''
  title = '';
  status = 0;
  lists = [];

  constructor() {
    // this.id = Crypto.randomUUID();
    this.id = Math.random().toString(16).substring(2, 8);
  }
}

export default Workspace;