

const getDOMRectObj = (ele) => {
  if (ele) {
    const rect = ele.getBoundingClientRect();
    return {
      bottom: rect.bottom,
      height: rect.height,
      width: rect.width,
      top: rect.top,
      left: rect.left,
      right: rect.right,
      x: rect.x,
      y: rect.y
    }
  }
  return {
    bottom: 0,
    height: 0,
    width: 0,
    top: 0,
    left: 0,
    right: 0,
    x: 0,
    y: 0
  }
}

const getHiddenDOMRectObj = (ele, eleClass, relative = false) => {
  let positionOn = 'absolute';
  let positionOff = 'absolute';
  if (relative) {
    positionOn = 'relative';
    positionOff = 'absolute';
  }
  ele.style.position = positionOn;
  ele.style.visibility = 'hidden';
  ele.style.opacity = '0';
  ele.classList.add(eleClass);
  let retObj = getDOMRectObj(ele);
  ele.classList.remove(eleClass);
  ele.style.opacity = '1';
  ele.style.visibility = 'visible';
  ele.style.position = positionOff;
  return retObj;
}

export { getDOMRectObj, getHiddenDOMRectObj }