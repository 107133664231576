import * as actions from "./actions";

export const DesktopContextReducer = (state, action) => {

  switch (action.type) {
    case actions.SET_WORKSPACES:
      return { ...state, workspaces: action.workspaces }
    case actions.SET_ACTIVE_WORKSPACE:
      return { ...state, activeWorkspace: action.activeWorkspace }
    case actions.SET_LISTS:
      return { ...state, lists: action.lists }
    case actions.SET_LIST:
      const listsCopy = [...state.lists];
      let filteredList = listsCopy.filter(list => list.id === action.list.id)
      if (filteredList.length > 0) {
        filteredList = filteredList[0];
        // filteredList = {...filteredList,action.list}
      }
      return { ...state, lists: [] }
    case actions.SET_LEFTNAV_ITEMS:
      return { ...state, leftNavItems: action.leftNavItems }
    case actions.SET_ACTIVE_LIST:
      return { ...state, activeList: action.activeList }
    case actions.SET_OPEN_WORKSPACES:
      return { ...state, openWorkspaces: action.openWorkspaces }
    case actions.SET_MULTI:
      return { ...state, ...action.payload }
    default:
      return { ...state }
  }
}