import React from 'react';

import styles from '../portalMenu.module.css';




const FullScreenPortalForm = ({ form, title, children, ...props }) => {
  return (
    <div className={styles.fullscreen_portal_form}>
      {children}
    </div>
  );
}


const Header = ({ children }) => {
  return (
    <div className={styles.form_header}>
      <h1>{children}</h1>
    </div>
  )
}
FullScreenPortalForm.Header = Header;


export default FullScreenPortalForm;