import React, { useState } from 'react';

import { CheckCircle, XCircle } from 'react-bootstrap-icons';

import * as eventTypes from '../../../../models/EventTypes';

import { publish } from '../../../../events';

import useWEForm from '../../../../hooks/useWEForm';
import WEForm from '../../WEForm/WEForm';

import FullScreenPortal from '../components/FullScreenPortal';
import FullScreenPortalForm from '../components/FullScreenPortalForm';
import PortalCloseButton from '../components/PortalCloseButton';

import { workspaceData } from '../../../data/WorkspaceData';
import { tags } from '../../../../data/SampleData';

import styles from './createListPortalForm.module.css';



const options = [
  { id: 'e693fe', value: "green", label: "Green" },
  { id: '1515a1', value: "blue", label: "Blue" },
  { id: '1302f1', value: "red", label: "Red" },
  { id: 'fd0f37', value: "yellow", label: "Yellow" },
  { id: '23e089', value: "orange", label: "Orange" },
  { id: '46c615', value: "pink", label: "Pink" },
  { id: '78a7bd', value: "purple", label: "Purple" },
  { id: '628960', value: "grey", label: "Grey" }
]








const CreateListPortalForm = ({ closeAction }) => {
  const { formData, registerFormInput, handleSubmit, handleFormReset } = useWEForm({
    name: '',
    title: '',
    tags: [],
    workspace: [],
    color: [],
    description: ''
  })

  const submitAction = (formData, isValid) => {
    console.log('');
    if (!isValid) return;
    publish(eventTypes.MENU_CLICK_NEW_LIST_REQ, { data: formData });
    handleFormReset();
    closeAction && closeAction();
  }

  return (
    <FullScreenPortal>
      <PortalCloseButton onClick={closeAction} />
      <FullScreenPortalForm>

        <WEForm>
          <WEForm.FormRow>
            <WEForm.Title style={{ color: 'snow' }} label={formData.name === '' ? 'New List' : formData.name} />
          </WEForm.FormRow>

          <WEForm.FormRow>
            <WEForm.TextField  {...registerFormInput('name', { required: true })} autoComplete={"off"} placeholder={'Name'} />
          </WEForm.FormRow>

          <WEForm.FormRow>
            <WEForm.Select {...registerFormInput('tags')} options={tags.map(tag => ({ id: tag.id, value: tag.name, label: tag.title }))} placeholder={'Select Tags'} />
          </WEForm.FormRow>

          <WEForm.FormRow>
            <WEForm.Select  {...registerFormInput('workspace')} options={workspaceData.map(ws => ({ id: ws.id, value: ws.name, label: ws.title }))} placeholder={'Select a Workspace'} />
          </WEForm.FormRow>

          <WEForm.FormRow>
            <WEForm.Select  {...registerFormInput('color')} options={options} placeholder={'Select a Color'} />
          </WEForm.FormRow>

          <WEForm.FormRow>
            <WEForm.TextArea  {...registerFormInput('description')} style={{ width: "100%" }} placeholder={'List description'} />
          </WEForm.FormRow>

          <WEForm.FormRow>

            <div className={styles.form_buttons}>
              <button className={styles.yes} onClick={e => handleSubmit(e, submitAction)} title={"Create List"}>
                <span>
                  <CheckCircle size={28} />
                </span>
              </button>
              <button className={styles.no} onClick={closeAction} title={"Cancel Creating List"}>
                <span>
                  <XCircle size={28} />
                </span>
              </button>
            </div>




            {/* <WEForm.Button btnColor={'red'} type="outline" style={{ width: '10ch' }}>
              Cancel
            </WEForm.Button>
            <WEForm.Button>
              {'SAVE'}
            </WEForm.Button> */}
          </WEForm.FormRow>

        </WEForm>
      </FullScreenPortalForm>
      {/* <Form onCancelClick={closeAction} /> */}
    </FullScreenPortal>
  );
}

export default CreateListPortalForm;