import React, { forwardRef } from 'react';
import StatusIndicator from '../baseComponents/StatusIndicator';
import ClearButton from '../baseComponents/ClearButton';



import styles from './textField.module.css';

const TextField = forwardRef(({ name, status = 'idle', value, onChange, onBlur, onReset, ...props }, ref) => {

  return (
    <div className={styles.container} style={props.style}>

      {(status === "busy") && <StatusIndicator className={styles.status} />}
      {(status === "idle" && value !== "") && <ClearButton className={styles.close_btn} onClick={(e) => onReset(e)} />}

      <input
        ref={ref}
        id={`${name}-${Math.floor(Math.random() * 1000)}`}
        name={name}
        className={`${styles.text_field} ${props.size ? styles[props.size] : ""}`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={props.disabled}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        required={props?.required || false}
      />

      <label className={styles[props.size]} htmlFor={`${name}-${Math.floor(Math.random() * 1000)}`} aria-label={`${props?.name ? props.name : "a"} text input`}>
        {props.placeholder}
      </label>

    </div>
  );
})

export default TextField;