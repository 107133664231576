import React from 'react';

import styles from '../select.module.css';

const SelectInput = ({ value }) => {
  return (
    <div className={styles.selected_value}>{value}</div>
  );
}

export default SelectInput;