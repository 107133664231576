import React from 'react';

import styles from './listSeperator.module.css';

const directions = {
    horizontal: styles.horizontal,
    vertical: styles.vertical
}

function ListSeperator({ direction="horizontal" }) {
    return (
        <div className={`${styles.seperator} ${directions[direction.toLocaleLowerCase()]}`}></div>
    );
}

export default ListSeperator;