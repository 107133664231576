import React from 'react';

import styles from './settingsMenu.module.css';
import { ShareFill, PencilSquare, PersonCheck, Tags, Newspaper, Gear, Laptop, ListOl, Trash3, ChevronRight } from 'react-bootstrap-icons';
import MenuGrid from './components/MenuGrid';

const menuGridItems = [
  {
    id: "1",
    icon: <Newspaper size={25} />,
    name: "details"
  },
  {
    id: "2",
    icon: <Tags size={25} />,
    name: "tags"
  },
  {
    id: "3",
    icon: <Gear size={25} />,
    name: "settings"
  },
  {
    id: "4",
    icon: <ListOl size={25} />,
    name: "priority"
  },
  {
    id: "5",
    icon: <PersonCheck size={25} />,
    name: "Subscribers"
  },
  {
    id: "6",
    icon: <Laptop size={25} />,
    name: "wspaces"
  },
]

const DefaultMenu = ({ ...props }) => {
  return (
    <div className={styles.menu_list}>
      <MenuGrid>

        {menuGridItems.map(item => {
          return (
            <button className={styles.menu_grid_item} key={item.id}>
              <div>{item.icon}</div>
              <div>{item.name}</div>
            </button>
          )
        })}

      </MenuGrid>

      {/* <ul>
        <li name="details">
          <span>Details</span>
          <span>
            <ChevronRight size={18} />
          </span>
        </li>
        <li name="tags">
          <span>Tags</span>
          <span>
            <ChevronRight size={18} />
          </span>
        </li>
        <li name="settings">
          <span>Settings</span>
          <span>
            <ChevronRight size={18} />
          </span>
        </li>
        <li name="priority">
          <span>Priority</span>
          <span>
            <ChevronRight size={18} />
          </span>
        </li>
      </ul> */}

    </div>
  );
}

export default DefaultMenu;