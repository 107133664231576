import React from 'react';

import CloseIcon from './iconComponents/CloseIcon';

const ClearButton = ({ onClick, ...props }) => {
  return (
    <button {...props} onClick={onClick}>
      <CloseIcon width={25} height={25} />
    </button>
  );
}

export default ClearButton;