import React, { forwardRef, useRef } from 'react';


import styles from './checkbox.module.css';

const CheckboxContainer = ({ children, ...props }) => {
  return (
    <div className={`${styles.cb_container} ${props.disabled ? styles.disabled : ""}`} style={props.style}>
      {children}
    </div>
  )
}

const LabelContainer = ({ children }) => {
  return (
    <label className={styles.checkbox}>
      {children}
    </label>
  )
}
CheckboxContainer.LabelContainer = LabelContainer;


const CheckboxInput = ({ children }) => {
  return (
    <span className={styles.input_span}>
      {children}
      <span className={styles.checkbox_input} id="checkbox_control">
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
          <path fill='none' stroke='currentColor' strokeWidth='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' />
        </svg>
      </span>
    </span>
  )
}
CheckboxContainer.CheckboxInput = CheckboxInput;

const Label = (props) => {
  return (
    <label htmlFor={props.id} className={styles.cb_label}>{props.text}</label>
  )
}
CheckboxContainer.Label = Label;


const generateUniqueId = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  const generator = () => {
    let result = '';
    const charactersLength = characters.length;
    for (let i; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  return generator();
}


const Checkbox = forwardRef(({ name, label = null, value, onChange, ...props }, ref) => {
  const idRef = useRef(`${generateUniqueId(6)}-${name}`);

  const handleOnChangeEvent = (e) => {
    onChange && onChange({ target: { value: e.target.checked } });
  }

  return (
    <CheckboxContainer {...props}>
      {label && <Label id={idRef.current} text={label} />}
      <LabelContainer>
        <CheckboxInput>
          <input ref={ref} type="checkbox" id={idRef.current} name={name} disabled={props.disabled} checked={(value === true) ? true : false} onChange={handleOnChangeEvent} />
        </CheckboxInput>
      </LabelContainer>
    </CheckboxContainer>
  );
})

export default Checkbox;