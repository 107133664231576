import React, { Children, cloneElement, useState } from 'react';

import Container from './Container';

import styles from '../weForm.module.css';

const MultiStepManager = ({ children, viewIndex }) => {
  const childArray = Children.toArray(children);

  // const [viewIndex, setViewIndex] = useState(0);

  return (
    <div className={styles.multi_step_mgr}>
      {Children.map(childArray, (child, index) => {
        let props = child.props;
        let active = parseInt(index) === parseInt(viewIndex);
        let newPropObj = { ...props, ...{ step: index, active: active } };
        return (
          <Container {...newPropObj} />
          // <div className={`${styles.step_container} ${active ? styles.fade_step_in : styles.fade_step_out}`}>
          //   <Container {...newPropObj} />
          // </div>
        )
      })}
    </div>
  );
}

export default MultiStepManager;