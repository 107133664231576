import { ShareFill, PencilSquare, Trash3, ChevronRight } from 'react-bootstrap-icons';

import styles from './settingsMenu.module.css';
import ListSeperator from '../../../../../ListSeperator/ListSeperator';
import DetailsMenu from './DetailsMenu';
import DefaultMenu from './DefaultMenu';
import { useState } from 'react';

const SettingsMenu = ({ submit, ...props }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [currentMenu, setCurrentMenu] = useState("");



  const handleMenuAction = (e, action) => {
    switch (action) {
      case 'delete':
        console.log('Delete menu action');
        break;
      case 'share':
        console.log('Share menu action');
        break;
      case 'edit':
        console.log('Edit menu action');
        break;
      case 'details':
        console.log('Edit menu action');
        break;

      default:
        break;
    }
  }

  const menuView = (_menu) => {
    switch (_menu) {
      case "filter":

        break;
      case "sort":

        break;
      case "details":

        return (<DetailsMenu />)

      default:
        return;
    }
  }

  const handleMenuItemClick = (e) => {

  }

  return (
    <div className={styles.menu}>
      <div className={styles.default_menu}>
        {showMenu && menuView(currentMenu)}
        <DefaultMenu onClick={handleMenuItemClick} isVisible={showMenu}>

        </DefaultMenu>


        <ListSeperator />
        <div className={styles.menu_bottom_nav}>
          <button name="edit_btn" className={styles.nav_btn} type="button" title='Edit list details' onClick={e => handleMenuAction(e, 'edit')}>
            <PencilSquare size={24} />
          </button>
          <button name="share_btn" className={styles.nav_btn} type="button" title='Share list' onClick={e => handleMenuAction(e, 'share')} >
            <ShareFill size={24} />
          </button>
          <button name="trash_btn" className={styles.nav_btn} type="button" title='Delete list' onClick={e => handleMenuAction(e, 'delete')}>
            <Trash3 size={24} />
          </button>
        </div>
      </div>






    </div >
  );
}

export default SettingsMenu;