import { useRef, useEffect } from 'react';
import useEffectOnce from '../../../../../hooks/useEffectOnce';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside';

import styles from './newListItemPOMenu.module.css';

function NewListItemPOMenu({ handleClose, coords, updateCoords }) {
  const menuRef = useRef(null);

  const handleClickOutside = (e) => {
    debugger;
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      handleClose(false);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", updateCoords);
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", updateCoords);
    }
  })

  return (
    <div ref={menuRef} className={styles.menu} style={{ ...coords }}>

    </div>
  );
}

export default NewListItemPOMenu;