export const getElementHeight = (_ele) => {
  if (_ele) {
    let offsetHeight = _ele.offsetHeight;
    let marginTop = parseInt(window.getComputedStyle(_ele).getPropertyValue('margin-top'));
    let marginBottom = parseInt(window.getComputedStyle(_ele).getPropertyValue('margin-bottom'));
    return offsetHeight + marginTop + marginBottom;
  }
  return -1
}

export const getClosestElementByLocalName = (_sourceEle, _selector) => {
  if (!_sourceEle) return null;
  let ele = _sourceEle;
  if (ele.localName.toLowerCase() !== _selector.toLowerCase()) {
    ele = ele.closest(_selector);
  }
  if (ele || ele?.localName.toLowerCase() === _selector) {
    return ele;
  }
  return null;
}

document.addEventListener('DOMContentLoaded', () => {
  for (const el of document.querySelectorAll("[placeholder][data-slots]")) {
    const pattern = el.getAttribute("placeholder"),
      slots = new Set(el.dataset.slots || "_"),
      prev = (j => Array.from(pattern, (c, i) => slots.has(c) ? j = i + 1 : j))(0),
      first = [...pattern].findIndex(c => slots.has(c)),
      accept = new RegExp(el.dataset.accept || "\\d", "g"),
      clean = input => {
        input = input.match(accept) || [];
        return Array.from(pattern, c =>
          input[0] === c || slots.has(c) ? input.shift() || c : c
        );
      },
      format = () => {
        const [i, j] = [el.selectionStart, el.selectionEnd].map(i => {
          i = clean(el.value.slice(0, i)).findIndex(c => slots.has(c));
          return i < 0 ? prev[prev.length - 1] : back ? prev[i - 1] || first : i;
        });
        el.value = clean(el.value).join``;
        el.setSelectionRange(i, j);
        back = false;
      };
    let back = false;
    el.addEventListener("keydown", (e) => back = e.key === "Backspace");
    el.addEventListener("input", format);
    el.addEventListener("focus", format);
    el.addEventListener("blur", () => el.value === pattern && (el.value = ""));
  }
});

{/* <label>Date time: 
    <input placeholder="dd/mm/yyyy hh:mm" data-slots="dmyh">
</label><br>
<label>Telephone:
    <input placeholder="+1 (___) ___-____" data-slots="_">
</label><br>
<label>MAC Address:
    <input placeholder="XX:XX:XX:XX:XX:XX" data-slots="X" data-accept="[\dA-H]">
</label><br></br> */}