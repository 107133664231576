import React from 'react';

import CloseIcon from './CloseIcon';

import styles from '../select.module.css';

const TagItem = ({ label, onClick }) => {
  return (
    <div className={styles.tag_item}>
      {label}
      <span onClick={onClick} className={styles.tag_close}>
        <CloseIcon width={"20"} height={"20"} />
      </span>
    </div>
  );
}

export default TagItem;