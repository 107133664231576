import React from 'react';
import * as eventTypes from '../../../../../models/EventTypes';

import useEffectOnce from '../../../../../hooks/useEffectOnce';
import { publish } from '../../../../../events';

import { ShareFill, PencilSquare, Trash3, ChevronRight } from 'react-bootstrap-icons';
import ListSeperator from '../../../../../ListSeperator/ListSeperator';

import styles from './settingsPOMenu.module.css';

const Menu = ({ coords, children }) => {
  return (
    <div className={styles.menu} style={{ ...coords }}>
      {children}
    </div>
  )
}



function SettingPOMenu({ coords, updateCoords, list }) {

  const handleDeleteBtnClick = (e) => {

    const evtObj = {
      listId: list.id,
      event: e
    }
    publish(eventTypes.DELETE_FROM_WORKSPACE_REQ, { id: list.id });
    // publish('deletelistrequest', evtObj); // Subscribed to by LBWorkspace.js
  }


  return (
    <Menu coords={coords}>
      <div className={styles.menu_body}>
        <div className={styles.menu_list}>
          <ul>
            <li name="details">
              <span>Details</span>
              <span>
                <ChevronRight size={18} />
              </span>
            </li>
            <li name="tags">
              <span>Tags</span>
              <span>
                <ChevronRight size={18} />
              </span>
            </li>
            <li name="settings">
              <span>Settings</span>
              <span>
                <ChevronRight size={18} />
              </span>
            </li>
            <li name="priority">
              <span>Priority</span>
              <span>
                <ChevronRight size={18} />
              </span>
            </li>
          </ul>
        </div>
      </div>
      <ListSeperator />
      <div className="listSeperator_seperator__tZqMg"></div>
      <div className={styles.menu_bottom_nav}>
        <button name="edit_btn" className={styles.nav_btn} type="button" title='Edit list details'>
          <PencilSquare size={24} />
        </button>
        <button name="share_btn" className={styles.nav_btn} type="button" title='Share list'>
          <ShareFill size={24} />
        </button>
        <button name="trash_btn" className={styles.nav_btn} type="button" title='Delete list' onClick={handleDeleteBtnClick}>
          <Trash3 size={24} />
        </button>
      </div>
    </Menu>
  )
}

export default SettingPOMenu;