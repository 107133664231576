import React from 'react';

import styles from '../../leftNavMenu.module.css';

const MenuFooter = ({ ...props }) => {
  return (
    <div className={styles.menu_footer}>

    </div>
  );
}

export default MenuFooter;