export const workspaceData = [
  {
    id: "256ef9",
    name: "garden_workspace",
    title: "Garden Project",
    primaryColor: "#F3B61F",
    status: 0,
    zed: 2,
    center: {
      x: 0,
      y: 0
    },
    activeList: {}
  },
  {
    id: "fd6ddc",
    name: "movie_workspace",
    title: "Movie Lists",
    primaryColor: "#20BF55",
    status: 0,
    zed: 2,
    center: {
      x: 0,
      y: 0
    },
    activeList: {}
  }
]