import React from 'react';

import styles from '../weForm.module.css';

const FormRow = ({ children, columns, ...props }) => {
  return (
    <div className={`${styles.form_row} ${columns ? "col-" + columns : ""}`} {...props}>
      {children}
    </div>
  );
}

export default FormRow;