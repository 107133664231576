import React, { createContext, useReducer } from 'react';
import { DesktopContextReducer } from './DesktopContextReducer';


const initialState = {
  workspaces: [],
  openWorkspaces: [],
  lists: [],
  leftNavItems: [],
  activeWorkspace: "",
  activeList: ""
}

export const DesktopContext = createContext();

export const DesktopContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(DesktopContextReducer, initialState);
  const value = { state, dispatch };
  return (
    <DesktopContext.Provider value={value}>
      {children}
    </DesktopContext.Provider>
  )
}